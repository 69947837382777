import axios from "axios";
import { TalentProfileDto } from "../models/TalentProfileDto";

import {
  TalentMessage,
  JobbOfferVisualForView,
  SkillRecommendation,
  PublishedCompoanyProfileDto,
  GetJobbOfferListDto,
  TalentAction,
  CreateTalentActionParams,
  WorkTitleDto,
  GetRecommendedCompaniesDto,
  GetTalentProfileForTalentDto,
  UpdateTalentUserDto,
} from "~/models/types";
import TalentHelpers from "~/helpers/talentHelpers";
import { $i18n } from "~/utils/i18n";
import { ListObject } from "~/models/ListObject";
import EducationHelpers from "~/helpers/EducationHelpers";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

const runPublicOnTest = true;

const publicApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://public.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://public-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runPublicOnTest
    ? "https://gritify-publicapi-test.azurewebsites.net/api"
    : "http://localhost:7072/api";

export default class TalentProfileService {
  static getSkillRecommendations(
    roleId: string,
    lang: string,
    accessToken: string
  ): Promise<SkillRecommendation[]> {
    return axios
      .get<SkillRecommendation[]>(
        `${gritifyApiUrl}/GetSkillRecommendations?roleId=${roleId}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getNeighbouringSkills(v: {
    skillIds: string[];
    excludeSkillIds: string[];
    lang: string;
    accessToken: string;
  }): Promise<ListObject[]> {
    return axios
      .post<ListObject[]>(
        `${gritifyApiUrl}/GetNeighbouringSkills`,
        {
          skillIds: v.skillIds,
          excludeSkillIds: v.excludeSkillIds,
          lang: v.lang,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getDomainRecommendations(v: {
    roleId: string;
    schoolId: string | null;
    accessToken: string;
  }): Promise<string[]> {
    return axios
      .get<string[]>(
        `${gritifyApiUrl}/GetDomainRecommendations?roleId=${v.roleId}&schoolId=${v.schoolId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getEmployerRecommendations(v: {
    lang: string;
    accessToken: string;
  }): Promise<GetRecommendedCompaniesDto[]> {
    return axios
      .get<GetRecommendedCompaniesDto[]>(
        `${gritifyApiUrl}/GetEmployerRecommendations?lang=${v.lang}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getTitleRecommendations(v: {
    lang: string;
    branchId: string;
    accessToken: string;
  }): Promise<WorkTitleDto[]> {
    return axios
      .get<WorkTitleDto[]>(
        `${gritifyApiUrl}/GetTitleRecommendations?lang=${v.lang}&branchId=${v.branchId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getSchoolRecommendations(v: {
    roleId: string;
    locationIds: string[];
    accessToken: string;
  }): Promise<ListObject[]> {
    return axios
      .post<ListObject[]>(
        `${gritifyApiUrl}/GetSchoolRecommendations`,
        {
          locationIds: v.locationIds,
          roleId: v.roleId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getCompanyProfileById(
    companyId: string,
    accessToken: string
  ): Promise<PublishedCompoanyProfileDto> {
    return axios
      .get<PublishedCompoanyProfileDto>(
        `${gritifyApiUrl}/GetPublishedProfile?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return {
          ...r.data,
          ingress: r.data.ingress || "",
          teamVisible: r.data.teamVisible || false,
          team: r.data.team.map((t, i) => ({
            ...t,
            id: i.toString(),
            isRemoved: false,
          })),
          offices: r.data.offices.map((o, i) => ({
            ...o,
            id: i.toString(),
            isRemoved: false,
          })),
        };
      });
  }

  static updateTalentUser(v: {
    updateTalentUserDto: UpdateTalentUserDto;
    accessToken: string;
  }): Promise<void> {
    return axios
      .put<void>(`${gritifyApiUrl}/UpdateTalentUser`, v.updateTalentUserDto, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  static getMatchingPreferencesForTalent(v: {
    companyId: string;
    recruitmentId: string;
    accessToken: string;
  }): Promise<string[]> {
    return axios
      .get<{
        column1: string[];
        column2: string[];
      }>(
        `${gritifyApiUrl}/GetMatchingPreferencesForTalent?companyId=${v.companyId}&recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return [...r.data.column1, ...r.data.column2];
      });
  }

  static addMessageFromTalent(
    message: string,
    recruitmentId: string,
    accessToken: string
  ): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/CreateMessage`,
        { comment: message, recruitmentId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getTalentMessagesForTalent(
    recruitmentId: string,
    accessToken: string
  ): Promise<TalentMessage[]> {
    return axios
      .get<TalentMessage[]>(
        `${gritifyApiUrl}/GetMessages?recruitmentId=${recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data.map(m => ({
          ...m,
          date: new Date(m.date),
          sender: {
            ...m.sender,
            firstName: m.sender.firstName || "",
            lastName: m.sender.lastName || "",
          },
        }));
      });
  }

  static createTalentAction(v: {
    createActionParams: CreateTalentActionParams;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RecruitmentTalentAction?action=${v.createActionParams.action}`,
        { ...v.createActionParams },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static acceptInvite(
    companyId: string,
    recruitmentId: string,
    talentName: string,
    accessToken: string
  ): Promise<void> {
    return this.createTalentAction({
      accessToken,
      createActionParams: {
        action: TalentAction.Accept,
        companyId,
        name: talentName,
        recruitmentId,
      },
    });
  }

  static acceptOpportunity(
    companyId: string,
    recruitmentId: string,
    talentName: string,
    accessToken: string
  ): Promise<void> {
    return this.createTalentAction({
      accessToken,
      createActionParams: {
        action: TalentAction.Interest,
        companyId,
        name: talentName,
        recruitmentId,
      },
    });
  }

  static declineInvite(
    companyId: string,
    recruitmentId: string,
    accessToken: string,
    reasonIds: string[],
    declineReasonTypes: string[],
    otherReason: string | null,
    ratingCompanyProcent: number,
    ratingJobProcent: number
  ): Promise<void> {
    return this.createTalentAction({
      accessToken,
      createActionParams: {
        action: TalentAction.Decline,
        companyId,
        otherReason,
        declineReasonIds: reasonIds,
        recruitmentId,
        declineReasonTypes,
        ratingCompanyProcent,
        ratingJobProcent,
      },
    });
  }

  static setProcessRead(v: {
    recruitmentId: string;
    token: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/UpdateLastRead?recruitmentId=${v.recruitmentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(x => x.data);
  }

  static getTalentJobOffers(
    accessToken: string
  ): Promise<JobbOfferVisualForView[]> {
    return axios
      .get<GetJobbOfferListDto[]>(`${gritifyApiUrl}/GetTalentJobOffers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data.reduce((acc: JobbOfferVisualForView[], x) => {
          const mappedJobOffer = TalentHelpers.mapToJobOfferToView(x);
          if (mappedJobOffer === null) {
            return acc;
          }
          return [...acc, mappedJobOffer];
        }, []);
      });
  }

  static updateTalentProfile(
    profile: TalentProfileDto,
    accessToken: string
  ): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateTalentProfile?lang=${$i18n.locale}`,
        profile,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static updateTalentLanguage(v: {
    accessToken: string;
    lang: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateTalentLanguage?lang=${v.lang}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getTalentUserEmail(accessToken: string): Promise<string> {
    return axios
      .get<string>(`${gritifyApiUrl}/GetTalentUserEmail`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(x => x.data);
  }

  static async getTalentProfile(
    accessToken: string,
    lang: string
  ): Promise<GetTalentProfileForTalentDto> {
    const result = await axios
      .get<GetTalentProfileForTalentDto>(
        `${gritifyApiUrl}/GetTalentProfile?lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(x => {
        return {
          talentProfileObject: {
            ...x.data.talentProfileObject,
            numberOfAutoDeclines:
              x.data.talentProfileObject.numberOfAutoDeclines || 0,
            pausedTo: x.data.talentProfileObject.pausedTo
              ? new Date(x.data.talentProfileObject.pausedTo)
              : null,
            understandRespondImportance:
              x.data.talentProfileObject.understandRespondImportance || false,
            experience: x.data.talentProfileObject.experience
              ? {
                  ...x.data.talentProfileObject.experience,
                  numberOfWorkYearsStartDate: x.data.talentProfileObject
                    .experience?.numberOfWorkYearsStartDate
                    ? new Date(
                        x.data.talentProfileObject.experience?.numberOfWorkYearsStartDate
                      )
                    : null,
                  education: (
                    x.data.talentProfileObject.experience.education || []
                  ).map(EducationHelpers.getEducationSaved),
                }
              : null,
          },
          talentUser: x.data.talentUser,
        };
      });

    return result;
  }

  static sendMessageToGritify(v: {
    name: string;
    title: string | null;
    message: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/ContactGritifyTalent`,
        {
          name: v.name,
          title: v.title,
          message: v.message,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static inviteFriend(v: {
    email: string;
    recruitmentId: string | null;
    message: string | null;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/InviteFriend`,
        {
          email: v.email,
          recruitmentId: v.recruitmentId,
          message: v.message,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static inviteFriendPublicOld(v: {
    email: string;
    message: string | null;
    token: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/InviteFriendPublicOld`,
        {
          email: v.email,
          message: v.message,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static inviteFriendPublic(v: {
    email: string;
    message: string | null;
    token: string;
    recruitmentId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/InviteFriendPublic`,
        {
          email: v.email,
          message: v.message,
          recruitmentId: v.recruitmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }
}
