import { flow, pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { Eq } from "fp-ts/lib/Eq";
import {
  isSomething,
  groupBy,
  getIsEqualProps,
  getAllIsTrue,
  arraysAreEqual,
  getDistinctWhere,
  groupByIsEqual,
  removeOptionals,
  getDistinct,
  getFirstPrioritizing,
  getIdsToRemoveIncludingChildren,
  upsertItem,
  getTextByIdForLangObject,
  tryGetById,
  sortListBy,
  tryGetThingFromRecord,
  getNumberRounded,
} from "./CommonHelperFunctions";
import {
  GetRolesDto,
  RobotStepperModule,
  RecruitmentListItem,
  LangObject,
  SourcingRobotRequirement,
  SourcingRobotRequirementsObject,
  RoleSpecificityStepValue,
  StepperModuleHint,
  SubRoleStepValue,
  GetRoleWithSkillsAndTasksDto,
  ThingToLoad,
  OpennessSettings,
  WantMoreCandidatesTip,
} from "~/models/types";
import { JobbOfferBaseInfo } from "~/models/JobbOfferBaseInfo";
import { WorkFromHome } from "~/models/WorkFromHome";

export type StaffIsRequiredValue =
  | { type: "notRequired"; moduleId: string }
  | { type: "notAnswered" }
  | { type: "required"; moduleId: string };

export type SkillGroup = {
  skillIds: string[];
  groupName: string | null;
  moduleId: string;
  module: RobotStepperModule;
};
export type TaskRequired = {
  taskId: string;
  taskName: string;
  moduleId: string;
  module: RobotStepperModule;
};

export type RobotRequirementProfileDto = {
  minimunYearsOfEducation: number;
  roleSpecificityAnswers: RoleSpecificityStepValue[];
  requiredLanguageIds: string[];
  salaryObject: {
    baseSalary: number;
    includeBonus: boolean;
    bonus: number | null;
  };
  yearsExperienceRange: {
    min: number;
    max: number | null;
  };
  selectedSubRoles: { roleId: string; subRoleId: string; moduleId: string }[];
  selectedSkillGroups: SkillGroup[];
  selectedRequiredTasks: {
    taskId: string;
    moduleId: string;
    module: RobotStepperModule;
  }[];
  requireStaffResponsibility: StaffIsRequiredValue;
};

const getActiveModules = (v: {
  allRoles: GetRolesDto[];
  jobRoleId: string;
}) => (selectedRoleIds: string[]) =>
  pipe(selectedRoleIds, A.flatMap(getActiveModulesForRoleId(v)));

export const getSubRoleModulesForRole = (v: {
  roleId: string;
  allRoles: GetRolesDto[];
  jobRoleId: string;
}): (RobotStepperModule & {
  subRoleIds: string[];
  moduleId: string;
  subTitle: Record<string, string>;
  roleId: string;
})[] =>
  pipe(
    v.roleId,
    getActiveModulesForRoleId(v),
    A.map(getSubRoleModule(v.roleId)),
    A.compact
  );

const getSubRoleModule = (roleId: string) => (
  v: RobotStepperModule
): O.Option<
  RobotStepperModule & {
    subRoleIds: string[];
    moduleId: string;
    subTitle: Record<string, string>;
    roleId: string;
  }
> => {
  switch (v.moduleType) {
    case "SubRolePickMultiple":
    case "SubRolePickSingle":
      return O.some({ ...v, roleId });
    case "MultipleSkillGroupsRequired":
    case "SkillGroupRequired":
    case "SkillsPickMultipleRequired":
    case "SkillsPickMultipleInVisualRequired":
    case "SkillsPickMultipleInVisualRequiredV2":
    case "MultipleSkillGroups":
    case "SkillGroup":
    case "SkillsPickMultiple":
    case "RoleSpecificity":
    case "Salary":
    case "StaffResponsibility":
    case "TasksPickMultiple":
    case "TasksPickMultipleInVisual":
    case "TasksPickMultipleInVisualRequired":
    case "TasksPickMultipleRequired":
    case "DomainGroupRequired":
    case "Education":
    case "Exit":
    case "ExperienceYears":
    case "IndustryGroupRequired":
    case "Language":
      return O.none;
  }
};

export const getSkillGroupIsSelected = <T extends { skillIds: string[] }>(
  selectedSubset: T[]
) => (skillGroup: T) =>
  selectedSubset.some(ss => isEqualSkillGroup(ss, skillGroup));

export const getSkillGroupIsSemiSelected = <T extends { skillIds: string[] }>(
  selectedSubset: T[]
) => (skillGroup: T) =>
  selectedSubset.some(ss =>
    ss.skillIds.every(x => skillGroup.skillIds.includes(x))
  );

export const getSelectedGroups = <T extends { skillIds: string[] }>(
  allGroups: T[]
) => (selectedSubSets: T[]): T[] =>
  allGroups.filter(getSkillGroupIsSelected(selectedSubSets));

const isEqualToSkillGroup = (a: { skillIds: string[] }) => (b: {
  skillIds: string[];
}): boolean => {
  if (a.skillIds.length !== b.skillIds.length) {
    return false;
  }

  if (a.skillIds.every(x => b.skillIds.includes(x))) {
    return true;
  }

  return false;
};

const getModuleByIdFromModules = <T extends { moduleId?: string }>(
  modules: T[]
) => (moduleId: string): O.Option<T> => {
  return pipe(
    modules,
    A.findFirst(x => x.moduleId === moduleId)
  );
};

const isActiveModule = (m: RobotStepperModule) => !m.isDepricated;

export const filterByModuleType = <
  T extends { moduleType: string },
  U extends T["moduleType"]
>(
  moduleType: U
) => (arr: T[]) => {
  type R = Extract<T, { moduleType: U }>;
  return arr.filter((item): item is R => item.moduleType === moduleType);
};

const filterByReqType = <
  T extends { requirementType: string },
  U extends T["requirementType"]
>(
  requirementType: U
) => (arr: T[]) => {
  type R = Extract<T, { requirementType: U }>;
  return arr.filter(
    (item): item is R => item.requirementType === requirementType
  );
};
const findFirstByReqType = <
  T extends { requirementType: string },
  U extends T["requirementType"]
>(
  requirementType: U
) => (arr: T[]) => {
  type R = Extract<T, { requirementType: U }>;
  return pipe(
    arr,
    A.findFirst((item): item is R => item.requirementType === requirementType)
  );
};
const findFirstByModuleType = <
  T extends { moduleType: string },
  U extends T["moduleType"]
>(
  moduleType: U
) => (arr: T[]) => {
  type R = Extract<T, { moduleType: U }>;
  return pipe(
    arr,
    A.findFirst((item): item is R => item.moduleType === moduleType)
  );
};

export const getUnansweredModules = (reqs: { moduleId: string }[]) => (
  modules: (RobotStepperModule & { moduleId?: string })[]
): RobotStepperModule[] =>
  modules.filter(m => {
    const isAnswered = reqs.some(x => x.moduleId === m.moduleId);
    return !isAnswered;
  });

export const handleRoleSpecificityAction = (
  requiements: { moduleId: string; roleIsSpecific: boolean }[]
) => (action: {
  moduleId: string;
  roleIsSpecific: boolean | null;
}): { moduleId: string; roleIsSpecific: boolean }[] =>
  pipe(
    O.fromNullable(action.roleIsSpecific),
    O.fold(
      () => requiements.filter(x => x.moduleId !== action.moduleId),
      roleIsSpecific =>
        pipe(
          requiements,
          addRequirement({ moduleId: action.moduleId, roleIsSpecific })
        )
    )
  );

export const getRoleSpecificityModulesFromSelectedSuRoles = (v: {
  allRoles: GetRolesDto[];
  roleId: string | null;
  selectedSubRoles: {
    subRoleId: string;
    moduleId: string;
    roleId: string;
  }[];
}) =>
  pipe(
    O.fromNullable(v.roleId),
    O.fold(
      () => [],
      roleId =>
        pipe(
          v.selectedSubRoles,
          A.map(x => x.subRoleId),
          A.append(roleId),
          getRoleSpecificityModulesFromRoleIds({
            allRoles: v.allRoles,
            jobRoleId: roleId,
          })
        )
    )
  );

const getRoleSpecificityModulesFromRoleIds = (v: {
  allRoles: GetRolesDto[];
  jobRoleId: string;
}) => (roleIds: string[]) =>
  pipe(roleIds, getActiveModules(v), filterByModuleType("RoleSpecificity"));

export const getRoleSpecificityModulesFromRequirements = (v: {
  allRoles: GetRolesDto[];
  requiements: SourcingRobotRequirement[];
  jobRoleId: string;
}) =>
  pipe(
    v.requiements,
    getSelectedRoleIdsFromRequirements,
    A.append(v.jobRoleId),
    getRoleSpecificityModulesFromRoleIds(v)
  );

export const getSelectedRoleIdsFromRequirements = (
  requirements: SourcingRobotRequirement[]
) =>
  pipe(
    requirements,
    filterByReqType("SubRole"),
    A.flatMap(x => x.subRoleIds),
    getDistinct
  );

const sourcingRobotRequirementsAreEqual = (
  a: SourcingRobotRequirement,
  b: SourcingRobotRequirement
): boolean => {
  switch (a.requirementType) {
    case "DomainGroupRequired":
    case "IndustryGroupRequired":
    case "SkillGroup":
    case "SkillGroupRequired": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.groupIsImportant),
        compareProps(x => x.moduleId),
      ]);
    }
    case "Education": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([compareProps(x => x.minimumYearsOfEducation)]);
    }
    case "Exit": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([
        compareProps(x => x.feedbackText),
        compareProps(x => x.moduleId),
      ]);
    }
    case "ExperienceYears": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([compareProps(x => x.max), compareProps(x => x.min)]);
    }

    case "Language": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const compareProps = getIsEqualProps({ ...a })({ ...b });
      return getAllIsTrue([
        compareProps(x => JSON.stringify(x.languageIdsWithRequired)),
      ]);
    }
    case "MultipleSkillGroups":
    case "MultipleSkillGroupsRequired": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.moduleId),
        () =>
          arraysAreEqual(
            aCopy.importantSkillGroupIds,
            bCopy.importantSkillGroupIds,
            (a, b) => a === b
          ),
      ]);
    }
    case "RoleSpecificity": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.moduleId),
        compareProps(x => x.roleIsSpecific),
      ]);
    }
    case "Salary": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.bonus),
        compareProps(x => x.salary),
      ]);
    }

    case "Skills":
    case "SkillsInVisualRequired":
    case "SkillsRequired": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.moduleId),
        () => arraysAreEqual(aCopy.skillIds, bCopy.skillIds, (a, b) => a === b),
      ]);
    }
    case "StaffResponsibility": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.staffIsRequired),
        compareProps(x => x.moduleId),
      ]);
    }
    case "SubRole": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.moduleId),
        compareProps(x => x.roleId),
        () =>
          arraysAreEqual(aCopy.subRoleIds, bCopy.subRoleIds, (a, b) => a === b),
      ]);
    }
    case "Tasks":
    case "TasksInVisual":
    case "TasksInVisualRequired":
    case "TasksRequired": {
      if (b.requirementType !== a.requirementType) {
        return false;
      }
      const aCopy = { ...a };
      const bCopy = { ...b };
      const compareProps = getIsEqualProps(aCopy)(bCopy);
      return getAllIsTrue([
        compareProps(x => x.moduleId),
        () => arraysAreEqual(aCopy.taskIds, bCopy.taskIds, (a, b) => a === b),
      ]);
    }
  }
};

export const getDefaultRequirementsObject = (v: {
  recruitmentId: string;
  allModules: RobotStepperModule[];
  lang: string;
  staffIsIncluded: boolean;
}): SourcingRobotRequirementsObject => {
  const defaultReqProfile = getRobotRequirementProfile(
    [],
    v.allModules,
    v.lang
  );

  const defaultRequirements = getRobotRequirements(defaultReqProfile, {
    staffIsIncluded: v.staffIsIncluded,
  });

  return {
    isManual: false,
    isStopped: true,
    recruitmentId: v.recruitmentId,
    requirements: defaultRequirements,
    shouldBeRunInitially: false,
  };
};

export const getIsEqualRobots = (
  oldRobot: SourcingRobotRequirementsObject | null,
  newRobot: SourcingRobotRequirementsObject
): boolean => {
  if (!oldRobot) {
    return false;
  }
  const compareProps = getIsEqualProps(oldRobot)(newRobot);

  return getAllIsTrue([
    compareProps(x => x.isManual),
    compareProps(x => x.isStopped),
    compareProps(x => x.recruitmentId),
    compareProps(x => x.shouldBeRunInitially),
    () =>
      arraysAreEqual(
        oldRobot.requirements
          .filter(removeUnimportantSkillGroups)
          .filter(removeDefaultEducation)
          .filter(removeDefaultMultipleSkillGroups),

        newRobot.requirements
          .filter(removeUnimportantSkillGroups)
          .filter(removeDefaultEducation)
          .filter(removeDefaultMultipleSkillGroups),
        sourcingRobotRequirementsAreEqual
      ),
  ]);
};

const removeUnimportantSkillGroups = (r: SourcingRobotRequirement): boolean =>
  r.requirementType === "SkillGroupRequired" ? r.groupIsImportant : true;

const removeDefaultEducation = (r: SourcingRobotRequirement): boolean =>
  r.requirementType === "Education" ? r.minimumYearsOfEducation > 0 : true;

const removeDefaultMultipleSkillGroups = (
  r: SourcingRobotRequirement
): boolean =>
  r.requirementType === "MultipleSkillGroupsRequired"
    ? r.importantSkillGroupIds.length > 0
    : true;

export const getRobotStatus = (v: {
  recListItem?: RecruitmentListItem | null;
}): "Active" | "Paused" | "NotExisting" => {
  if (!v.recListItem) {
    return "NotExisting";
  }

  if (!v.recListItem.startedDate) {
    return "NotExisting";
  }

  const boolOrNullArr = [
    v.recListItem.newRobotIsActive,
    v.recListItem.manualRobotIsActive,
    v.recListItem.oldRobotIsActive,
  ];

  if (boolOrNullArr.every(x => x === null)) {
    return "NotExisting";
  }

  return boolOrNullArr.includes(true) ? "Active" : "Paused";
};

const getRobotRequirementsForTaskGroup = (rt: {
  taskIds: string[];
  moduleId: string;
  module: RobotStepperModule;
}): SourcingRobotRequirement | null => {
  switch (rt.module.moduleType) {
    case "TasksPickMultipleInVisualRequired":
      return {
        requirementType: "TasksInVisualRequired",
        moduleId: rt.moduleId,
        taskIds: rt.taskIds,
      };
    case "TasksPickMultipleRequired":
      return {
        requirementType: "TasksRequired",
        moduleId: rt.moduleId,
        taskIds: rt.taskIds,
      };
    case "MultipleSkillGroupsRequired":
    case "SkillGroupRequired":
    case "SkillsPickMultipleRequired":
    case "SkillsPickMultipleInVisualRequired":
    case "SkillsPickMultipleInVisualRequiredV2":
    case "MultipleSkillGroups":
    case "SkillGroup":
    case "SkillsPickMultiple":
    case "RoleSpecificity":
    case "Salary":
    case "StaffResponsibility":
    case "SubRolePickMultiple":
    case "SubRolePickSingle":
    case "TasksPickMultiple":
    case "TasksPickMultipleInVisual":
    case "DomainGroupRequired":
    case "Education":
    case "Exit":
    case "ExperienceYears":
    case "IndustryGroupRequired":
    case "Language":
      return null;
  }
};

const getRobotRequirementsForTaskRequirements = (
  requiredTasks: {
    taskId: string;
    moduleId: string;
    module: RobotStepperModule;
  }[]
): SourcingRobotRequirement[] => {
  return groupBy(requiredTasks, x => x.moduleId).flatMap(g => {
    const result = getRobotRequirementsForTaskGroup({
      module: g[0]!.module,
      moduleId: g[0]!.moduleId,
      taskIds: g.map(x => x.taskId),
    });
    return result ? [result] : [];
  });
};

const getSubRoleRequirementsFromSelectedSubRoles = (
  selectedSubRoles: { roleId: string; subRoleId: string; moduleId: string }[]
) => {
  return groupBy(selectedSubRoles, x => x.roleId).map(srGroup => {
    const req: SourcingRobotRequirement = {
      requirementType: "SubRole",
      roleId: srGroup[0]!.roleId,
      subRoleIds: srGroup.map(x => x.subRoleId),
      moduleId: srGroup[0]!.moduleId,
    };
    return req;
  });
};

export const getRobotIsPublished = (v: {
  allRoles: GetRolesDto[];
  allRecruitments: RecruitmentListItem[];
  recruitmentId: string;
}): boolean =>
  pipe(
    v.recruitmentId,
    tryGetById(v.allRecruitments),
    O.chain(x => O.fromNullable(x.roleId)),
    O.chain(tryGetById(v.allRoles)),
    O.map(x => x.robotIsPublished),
    O.getOrElseW(() => false)
  );

const getRobotRequirementBySkillGroup = (
  sg: SkillGroup
): SourcingRobotRequirement | null => {
  switch (sg.module.moduleType) {
    case "MultipleSkillGroupsRequired": {
      return {
        requirementType: "MultipleSkillGroupsRequired",
        importantSkillGroupIds: sg.module.skillGroups
          .filter(isEqualToSkillGroup(sg))
          .map(sg => sg.id),
        moduleId: sg.moduleId,
      };
    }
    case "SkillGroupRequired": {
      return {
        requirementType: "SkillGroupRequired",
        groupIsImportant: true,
        moduleId: sg.moduleId,
      };
    }
    case "SkillsPickMultipleRequired": {
      return {
        requirementType: "SkillsRequired",
        moduleId: sg.moduleId,
        skillIds: sg.skillIds,
      };
    }
    case "SkillsPickMultipleInVisualRequired": {
      return {
        requirementType: "SkillsInVisualRequired",
        moduleId: sg.moduleId,
        skillIds: sg.skillIds,
      };
    }
    case "SkillsPickMultipleInVisualRequiredV2": {
      return {
        requirementType: "SkillsInVisualRequired",
        moduleId: sg.moduleId,
        skillIds: sg.skillIds,
      };
    }
    case "MultipleSkillGroups":
    case "SkillGroup":
    case "SkillsPickMultiple":
    case "RoleSpecificity":
    case "Salary":
    case "StaffResponsibility":
    case "SubRolePickMultiple":
    case "SubRolePickSingle":
    case "TasksPickMultiple":
    case "TasksPickMultipleInVisual":
    case "TasksPickMultipleInVisualRequired":
    case "TasksPickMultipleRequired":
    case "DomainGroupRequired":
    case "Education":
    case "Exit":
    case "ExperienceYears":
    case "IndustryGroupRequired":
    case "Language":
      return null;
  }
};

const getRobotRequirementsForSkillGroups = (
  selectedSkillGroups: SkillGroup[]
): SourcingRobotRequirement[] => {
  return groupBy(
    selectedSkillGroups
      .map(getRobotRequirementBySkillGroup)
      .filter(isSomething),
    x =>
      x.requirementType === "MultipleSkillGroupsRequired" ||
      x.requirementType === "SkillsRequired" ||
      x.requirementType === "SkillsInVisualRequired"
        ? x.moduleId + "|" + x.requirementType
        : x.requirementType
  ).flatMap(srGroup => {
    const firstInGroup = srGroup[0]!;
    if (firstInGroup!.requirementType === "MultipleSkillGroupsRequired") {
      const req: SourcingRobotRequirement = {
        requirementType: "MultipleSkillGroupsRequired",
        importantSkillGroupIds: srGroup.flatMap(x =>
          x.requirementType === "MultipleSkillGroupsRequired"
            ? x.importantSkillGroupIds
            : []
        ),
        moduleId: firstInGroup.moduleId,
      };
      return [req];
    }
    if (firstInGroup!.requirementType === "SkillsRequired") {
      const req: SourcingRobotRequirement = {
        requirementType: "SkillsRequired",
        skillIds: srGroup.flatMap(x =>
          x.requirementType === "SkillsRequired" ? x.skillIds : []
        ),
        moduleId: firstInGroup.moduleId,
      };
      return [req];
    }
    if (firstInGroup!.requirementType === "SkillsInVisualRequired") {
      const req: SourcingRobotRequirement = {
        requirementType: "SkillsInVisualRequired",
        skillIds: srGroup.flatMap(x =>
          x.requirementType === "SkillsInVisualRequired" ? x.skillIds : []
        ),
        moduleId: firstInGroup.moduleId,
      };
      return [req];
    }
    return srGroup;
  });
};

const hasModuleId = <T>(item: T): item is T & { moduleId: string } =>
  !!(item as any).moduleId;

const getGetSkillGroupsFromRequirement = (
  getModuleById: (
    moduleId: string
  ) => O.Option<
    RobotStepperModule & {
      moduleId: string;
    }
  >
) => (r: { moduleId: string; skillIds: string[] }): SkillGroup[] =>
  pipe(
    (() => {
      console.log({ r });
      return r;
    })(),
    r => r.moduleId,
    getModuleById,
    O.map(module =>
      r.skillIds.map(skillId => ({
        groupName: null,
        module,
        moduleId: module.moduleId,
        skillIds: [skillId],
      }))
    ),
    O.getOrElseW(() => [])
  );

const getGetSelectedRequiredTasksFromReq = (
  getModuleById: (
    moduleId: string
  ) => O.Option<
    RobotStepperModule & {
      moduleId: string;
    }
  >
) => (r: { moduleId: string; taskIds: string[] }) =>
  pipe(
    r.moduleId,
    getModuleById,
    O.map(module =>
      r.taskIds.map(taskId => ({
        module,
        moduleId: module.moduleId,
        taskId,
      }))
    ),
    O.getOrElseW(() => [])
  );

export const getRobotRequirementProfile = (
  reqs: SourcingRobotRequirement[],
  modules: RobotStepperModule[],
  lang: string
): RobotRequirementProfileDto => {
  const activeModules = modules.filter(isActiveModule);
  const getModuleById = getModuleByIdFromModules(
    activeModules.filter(hasModuleId)
  );

  const getSkillGroupsFromRequirement = getGetSkillGroupsFromRequirement(
    getModuleById
  );

  const getSelectedRequiredTasksFromReq = getGetSelectedRequiredTasksFromReq(
    getModuleById
  );

  const result: RobotRequirementProfileDto = {
    minimunYearsOfEducation: pipe(
      reqs,
      findFirstByReqType("Education"),
      O.map(x => x.minimumYearsOfEducation),
      O.getOrElseW(() => 0)
    ),
    roleSpecificityAnswers: pipe(reqs, filterByReqType("RoleSpecificity")),
    requiredLanguageIds: pipe(
      reqs,
      findFirstByReqType("Language"),
      O.map(x => Object.keys(x.languageIdsWithRequired)),
      O.getOrElseW(() => [])
    ),
    salaryObject: pipe(
      reqs,
      findFirstByReqType("Salary"),
      O.map(x => ({
        baseSalary: x.salary,
        includeBonus: x.bonus > 0,
        bonus: x.bonus === 0 ? null : x.bonus,
      })),
      O.getOrElseW(() => ({
        baseSalary: 0,
        includeBonus: false,
        bonus: 0,
      }))
    ),
    yearsExperienceRange: pipe(
      reqs,
      findFirstByReqType("ExperienceYears"),
      O.getOrElseW(() => ({
        min: 0,
        max: null,
      }))
    ),
    selectedSubRoles: pipe(
      reqs,
      filterByReqType("SubRole"),
      A.flatMap(r =>
        r.subRoleIds.map(subRoleId => ({
          roleId: r.roleId,
          subRoleId,
          moduleId: r.moduleId,
        }))
      )
    ),
    selectedSkillGroups: [
      ...pipe(
        reqs,
        filterByReqType("SkillsRequired"),
        A.flatMap(getSkillGroupsFromRequirement)
      ),
      ...pipe(
        reqs,
        filterByReqType("SkillsInVisualRequired"),
        A.flatMap(getSkillGroupsFromRequirement)
      ),
      ...pipe(
        reqs,
        filterByReqType("SkillGroupRequired"),
        A.map(getSkillGroupFromSkillGroupRequired(activeModules, lang)),
        A.compact
      ),
      ...pipe(
        reqs,
        filterByReqType("MultipleSkillGroupsRequired"),
        A.flatMap(
          getSkillGroupsFromMultipleSkillGroupsRequired(activeModules, lang)
        )
      ),
    ],
    selectedRequiredTasks: [
      ...pipe(
        reqs,
        filterByReqType("TasksRequired"),
        A.flatMap(getSelectedRequiredTasksFromReq)
      ),
      ...pipe(
        reqs,
        filterByReqType("TasksInVisualRequired"),
        A.flatMap(getSelectedRequiredTasksFromReq)
      ),
    ],
    requireStaffResponsibility: pipe(
      reqs,
      filterByReqType("StaffResponsibility"),
      A.findFirst(() => true),
      O.map(x =>
        x.staffIsRequired
          ? ({ type: "required", moduleId: x.moduleId } as const)
          : ({ type: "notRequired", moduleId: x.moduleId } as const)
      ),
      O.getOrElseW(() => ({ type: "notAnswered" } as const))
    ),
  };

  return result;
};

export const isActiveRequirement = (allModules: RobotStepperModule[]) => (
  req: SourcingRobotRequirement
): boolean => {
  if (!hasModuleId(req)) {
    return true;
  }

  const moduleIsDepricated = pipe(
    allModules,
    A.filter(hasModuleId),
    A.findFirst(x => x.moduleId === req.moduleId),
    O.chain(x => O.fromNullable(x.isDepricated)),
    O.getOrElseW(() => false)
  );

  return !moduleIsDepricated;
};

export const getRobotRequirements = (
  reqProfile: RobotRequirementProfileDto,
  additionalData: {
    staffIsIncluded: boolean;
  }
): SourcingRobotRequirement[] => {
  const educationRequirement: SourcingRobotRequirement = {
    requirementType: "Education",
    minimumYearsOfEducation: reqProfile.minimunYearsOfEducation,
  };

  const laguageReq: SourcingRobotRequirement | null = !reqProfile
    .requiredLanguageIds.length
    ? null
    : {
        requirementType: "Language",
        languageIdsWithRequired: reqProfile.requiredLanguageIds.reduce(
          (acc: Record<string, boolean>, langId) => {
            return {
              ...acc,
              [langId]: true,
            };
          },
          {}
        ),
      };

  const salaryReq: SourcingRobotRequirement = {
    requirementType: "Salary",
    bonus: reqProfile.salaryObject.includeBonus
      ? reqProfile.salaryObject.bonus ?? 0
      : 0,
    salary: reqProfile.salaryObject.baseSalary,
  };

  const experienceYearsReq: SourcingRobotRequirement = {
    requirementType: "ExperienceYears",
    max: reqProfile.yearsExperienceRange.max,
    min: reqProfile.yearsExperienceRange.min,
  };

  const staff = !additionalData.staffIsIncluded
    ? []
    : reqProfile.requireStaffResponsibility.type === "required"
    ? [
        {
          requirementType: "StaffResponsibility",
          staffIsRequired: true,
          moduleId: reqProfile.requireStaffResponsibility.moduleId,
        } as const,
      ]
    : reqProfile.requireStaffResponsibility.type === "notRequired"
    ? [
        {
          requirementType: "StaffResponsibility",
          staffIsRequired: false,
          moduleId: reqProfile.requireStaffResponsibility.moduleId,
        } as const,
      ]
    : [];

  return [
    educationRequirement,
    ...(laguageReq ? [laguageReq] : []),
    salaryReq,
    experienceYearsReq,
    ...getSubRoleRequirementsFromSelectedSubRoles(reqProfile.selectedSubRoles),
    ...getRobotRequirementsForSkillGroups(reqProfile.selectedSkillGroups),
    ...getRobotRequirementsForTaskRequirements(
      reqProfile.selectedRequiredTasks
    ),
    ...reqProfile.roleSpecificityAnswers.map<SourcingRobotRequirement>(x => ({
      requirementType: "RoleSpecificity",
      roleIsSpecific: x.roleIsSpecific,
      moduleId: x.moduleId,
    })),
    ...staff,
  ];
};

export const isEqualSkillGroup = <T extends { skillIds: string[] }>(
  a: T,
  b: T
): boolean => {
  return isEqualToSkillGroup(a)(b);
};

const getMatchingIdFromInitialSkillGroups = (v: {
  skillIdBySkillIdInVisualDict: Record<string, string>;
  initialSelectedSkillGroups: SkillGroup[];
}) =>
  pipe(
    Object.values(v.skillIdBySkillIdInVisualDict),
    A.findFirst(x =>
      v.initialSelectedSkillGroups.some(sg => sg.skillIds.every(y => y === x))
    )
  );

const getSkillGroupsFromModule = (v: {
  module: RobotStepperModule;
  lang: string;
  visualSkillIds: string[];
  initialSelectedSkillGroups: SkillGroup[];
}): SkillGroup[] => {
  switch (v.module.moduleType) {
    case "MultipleSkillGroupsRequired": {
      const mod = v.module;
      return v.module.skillGroups.flatMap(sg => {
        const groupName = sg.groupName[v.lang] ?? null;

        return [
          {
            groupName,
            skillIds: sg.skillIds,
            module: mod,
            moduleId: mod.moduleId,
          },
        ];
      });
    }
    case "SkillGroupRequired": {
      const mod = v.module;
      const groupName = mod.groupName?.[v.lang] ?? null;

      if (
        mod.onlyShowIfExistsInVisual &&
        !v.visualSkillIds.some(x => mod.skillIds.includes(x))
      ) {
        return [];
      }

      return [
        {
          groupName,
          skillIds: mod.skillIds,
          module: mod,
          moduleId: mod.moduleId,
        },
      ];
    }
    case "SkillsPickMultipleRequired": {
      const mod = v.module;
      return mod.skillIds.map(skillId => ({
        groupName: null,
        skillIds: [skillId],
        module: mod,
        moduleId: mod.moduleId,
      }));
    }
    case "SkillsPickMultipleInVisualRequired": {
      const mod = v.module;
      return mod.skillIds.flatMap(skillId =>
        v.visualSkillIds.includes(skillId)
          ? [
              {
                groupName: null,
                skillIds: [skillId],
                module: mod,
                moduleId: mod.moduleId,
              },
            ]
          : []
      );
    }
    case "SkillsPickMultipleInVisualRequiredV2": {
      const mod = v.module;

      return pipe(
        v.visualSkillIds,
        A.map(visualSkillId =>
          pipe(
            O.fromNullable(mod.skillIdBySkillIdInVisualDict[visualSkillId]),
            O.orElse(() =>
              getMatchingIdFromInitialSkillGroups({
                initialSelectedSkillGroups: v.initialSelectedSkillGroups,
                skillIdBySkillIdInVisualDict: mod.skillIdBySkillIdInVisualDict,
              })
            ),
            O.map(skillId => ({
              groupName: null,
              skillIds: [skillId],
              module: mod,
              moduleId: mod.moduleId,
            }))
          )
        ),
        A.compact
      );
    }
    case "MultipleSkillGroups":
    case "SkillGroup":
    case "SkillsPickMultiple":
    case "RoleSpecificity":
    case "Salary":
    case "StaffResponsibility":
    case "SubRolePickMultiple":
    case "SubRolePickSingle":
    case "TasksPickMultiple":
    case "TasksPickMultipleInVisual":
    case "TasksPickMultipleInVisualRequired":
    case "TasksPickMultipleRequired":
    case "DomainGroupRequired":
    case "Education":
    case "Exit":
    case "ExperienceYears":
    case "IndustryGroupRequired":
    case "Language":
      return [];
  }
};

export const getSkillGroupsForRolesAndVisualSkillIds = (v: {
  jobRoleId: string;
  allRoles: GetRolesDto[];
  selectedRoleIds: string[];
  visualSkillIds: string[];
  selectedModuleIds: string[];
  lang: string;
  initialSelectedSkillGroups: SkillGroup[];
}): SkillGroup[] => {
  return pipe(
    v.selectedRoleIds,
    getActiveModules(v),
    A.flatMap(m =>
      getSkillGroupsFromModule({
        lang: v.lang,
        module: m,
        visualSkillIds: v.visualSkillIds,
        initialSelectedSkillGroups: v.initialSelectedSkillGroups,
      })
    ),
    groupByIsEqual(isEqualSkillGroup),
    A.map(getFirstPrioritizing(x => v.selectedModuleIds.includes(x.moduleId))),
    A.compact
  );
};

export const askAboutStaffResponsibilityRequired = (v: {
  allRoles: GetRolesDto[];
  jobRoleId: string;
}) => (
  selectedRoleIds: string[]
):
  | { type: "DontAsk" }
  | { type: "AskButNotRequired" }
  | {
      type: "Ask";
      module: RobotStepperModule & { moduleType: "StaffResponsibility" };
    } => {
  const role = v.allRoles.find(x => x.id === v.jobRoleId);

  if (!(role?.robotIsPublished ?? false)) {
    return role?.askIfStaffIsIncluded
      ? { type: "AskButNotRequired" }
      : { type: "DontAsk" };
  }
  return pipe(
    selectedRoleIds,
    getActiveModules(v),
    findFirstByModuleType("StaffResponsibility"),
    O.map(
      x =>
        ({
          type: "Ask",
          module: x,
        } as const)
    ),
    O.getOrElseW(() => ({ type: "DontAsk" } as const))
  );
};

export const getTasksForVisualTaskAndRoles = (v: {
  allRoles: GetRolesDto[];
  jobRoleId: string;
  selectedRoleIds: string[];
  visualTaskIds: string[];
  selectedModuleIds: string[];
  taskNamesById: Record<string, string>;
}): TaskRequired[] =>
  pipe(
    v.selectedRoleIds,
    getActiveModules(v),
    A.flatMap(
      getTasksFromModule({
        visualTaskIds: v.visualTaskIds,
        taskNamesById: v.taskNamesById,
      })
    ),
    getDistinctWhere((a, b) => a.taskId === b.taskId)
  );

const getTasksFromModule = (v: {
  visualTaskIds: string[];
  taskNamesById: Record<string, string>;
}) => (module: RobotStepperModule): TaskRequired[] => {
  switch (module.moduleType) {
    case "TasksPickMultipleInVisualRequired": {
      const mod = module;
      return mod.taskIds.flatMap(taskId =>
        v.visualTaskIds.includes(taskId)
          ? [
              {
                taskId,
                taskName: v.taskNamesById[taskId] ?? "",
                moduleId: mod.moduleId,
                module: mod,
              },
            ]
          : []
      );
    }
    case "TasksPickMultipleRequired": {
      const mod = module;
      return mod.taskIds.map(taskId => ({
        taskId,
        taskName: v.taskNamesById[taskId] ?? "",
        moduleId: mod.moduleId,
        module: mod,
      }));
    }
    case "MultipleSkillGroupsRequired":
    case "SkillGroupRequired":
    case "SkillsPickMultipleRequired":
    case "SkillsPickMultipleInVisualRequired":
    case "SkillsPickMultipleInVisualRequiredV2":
    case "MultipleSkillGroups":
    case "SkillGroup":
    case "SkillsPickMultiple":
    case "RoleSpecificity":
    case "Salary":
    case "StaffResponsibility":
    case "SubRolePickMultiple":
    case "SubRolePickSingle":
    case "TasksPickMultiple":
    case "TasksPickMultipleInVisual":
    case "DomainGroupRequired":
    case "Education":
    case "Exit":
    case "ExperienceYears":
    case "IndustryGroupRequired":
    case "Language":
      return [];
  }
};

export const getNameForSkillGroup = <
  T extends { groupName: string | null; skillIds: string[] }
>(v: {
  skillGroup: T;
  skillsById: Map<string, LangObject | Promise<LangObject>>;
  lang: string;
  andMoreText: string;
}): string | null => {
  const getSkillNameById = getTextByIdForLangObject({
    lang: v.lang,
    langObjectById: v.skillsById,
  });

  return pipe(
    O.fromNullable(v.skillGroup.groupName),
    O.orElse(
      tryGetNameFromSingleSkill(v.skillGroup.skillIds, getSkillNameById)
    ),
    O.getOrElse(
      getNameFromMultipleSkills(
        v.skillGroup.skillIds,
        getSkillNameById,
        v.andMoreText
      )
    )
  );
};

export const getModulesByRoleIdRecursive = (v: {
  roleId: string;
  passedRoleIds: string[];
  allRoles: GetRolesDto[];
}): RobotStepperModule[] => {
  const role = v.allRoles.find(r => r.id === v.roleId);

  const newPassedRoleIds = [...v.passedRoleIds, v.roleId];

  if (!role) {
    return [];
  }

  return (role.robotStepperModules ?? []).reduce(
    (acc: RobotStepperModule[], m) => {
      if (
        m.moduleType === "SubRolePickSingle" ||
        m.moduleType === "SubRolePickMultiple"
      ) {
        return [
          ...acc,
          m,
          ...m.subRoleIds
            .filter(srid => !newPassedRoleIds.includes(srid))
            .reduce(
              (acc2: RobotStepperModule[], subRoleId) => [
                ...acc2,
                ...getModulesByRoleIdRecursive({
                  allRoles: v.allRoles,
                  roleId: subRoleId,
                  passedRoleIds: newPassedRoleIds,
                }),
              ],
              []
            ),
        ];
      }

      return [...acc, m];
    },
    []
  );
};

const getSkillGroupFromSkillGroupRequired = (
  modules: RobotStepperModule[],
  lang: string
) => (req: {
  requirementType: "SkillGroupRequired";
  moduleId: string;
  groupIsImportant: boolean;
}) => {
  if (!req.groupIsImportant) {
    return O.none;
  }
  return pipe(
    req.moduleId,
    getModuleByIdFromModules(
      pipe(modules, filterByModuleType("SkillGroupRequired"))
    ),
    O.map(module => ({
      groupName: module.groupName?.[lang] ?? null,
      module,
      moduleId: module.moduleId,
      skillIds: module.skillIds,
    }))
  );
};

const getSkillGroupsFromMultipleSkillGroupsRequired = (
  modules: RobotStepperModule[],
  lang: string
) => (req: {
  requirementType: "MultipleSkillGroupsRequired";
  moduleId: string;
  importantSkillGroupIds: string[];
}) => {
  return pipe(
    req.moduleId,
    getModuleByIdFromModules(
      pipe(modules, filterByModuleType("MultipleSkillGroupsRequired"))
    ),
    O.map(module =>
      pipe(
        req.importantSkillGroupIds,
        A.map(sgid =>
          pipe(
            module.skillGroups,
            A.findFirst(x => x.id === sgid),
            O.map(skillGroup => ({
              groupName: skillGroup.groupName[lang] ?? null,
              module,
              moduleId: req.moduleId,
              skillIds: skillGroup.skillIds ?? [],
            }))
          )
        ),
        A.compact
      )
    ),
    O.getOrElseW(() => [])
  );
};

const getNameFromMultipleSkills = (
  skillIds: string[],
  tryGetSkillNameById: (skillId: string) => O.Option<string>,
  andMoreText: string
) => {
  return () =>
    pipe(
      skillIds,
      A.takeLeft(2),
      A.map(tryGetSkillNameById),
      removeOptionals,
      O.fromPredicate(A.isNonEmpty),
      O.map(xs => `${xs.join(", ")} ${andMoreText.trim()}`),
      O.getOrElseW(() => null)
    );
};

const tryGetNameFromSingleSkill = (
  skillIds: string[],
  getSkillNameById: (skillId: string) => O.Option<string>
) => {
  return () =>
    pipe(
      skillIds,
      O.fromPredicate(x => x.length === 1),
      O.flatMap(A.head),
      O.flatMap(getSkillNameById)
    );
};

const getActiveModulesForRoleId = (v: {
  allRoles: GetRolesDto[];
  jobRoleId: string;
}) => (rid: string): RobotStepperModule[] => {
  return pipe(
    v.allRoles,
    A.findFirst(
      x => x.id === rid && (x.id !== v.jobRoleId || x.robotIsPublished)
    ),
    O.map(x => x.robotStepperModules?.filter(isActiveModule) ?? []),
    O.getOrElseW(() => [])
  );
};

const removeSubRole = <T extends { subRoleId: string }>(
  subRoleModules: {
    subRoleIds: string[];
    roleId: string;
  }[],
  roleId: string
) => (selectedSubRoles: T[]): T[] => {
  const subRolesModulesMaped = subRoleModules.map(x => {
    return {
      id: x.roleId,
      childrenIds: x.subRoleIds,
    };
  });

  const roleIdsToRemove = getIdsToRemoveIncludingChildren(
    subRolesModulesMaped,
    roleId
  );

  return selectedSubRoles.filter(sr => !roleIdsToRemove.includes(sr.subRoleId));
};

const includesEq = <T>(eq: Eq<T>) => (item: T) => (arr: T[]): boolean =>
  arr.some(x => eq.equals(x, item));

const eqSubRole: Eq<{ subRoleId: string }> = {
  equals: (a, b) => a.subRoleId === b.subRoleId,
};

const includesSubRole = includesEq(eqSubRole);

export const toggleSubRoleSelection = <T extends { subRoleId: string }>(
  subRoleToToggle: T,
  module: { moduleType: string; subRoleIds?: string[] },
  selectedNodes: T[],
  subRoleModules: {
    subRoleIds: string[];
    roleId: string;
  }[]
): T[] => {
  const isRemoval = pipe(selectedNodes, includesSubRole(subRoleToToggle));

  return isRemoval
    ? pipe(
        selectedNodes,
        removeSubRole(subRoleModules, subRoleToToggle.subRoleId)
      )
    : pipe(
        selectedNodes,
        removeSiblingsIfSingleSelect(module, subRoleModules),
        addSubRole(subRoleToToggle)
      );
};

const addSubRole = <T extends { subRoleId: string }>(x: T) =>
  upsertItem<T>((a, b) => a.subRoleId === b.subRoleId)(x);

const addRequirement = <T extends { moduleId: string }>(x: T) =>
  upsertItem<T>((a, b) => a.moduleId === b.moduleId)(x);

const removeSiblingsIfSingleSelect = <T extends { subRoleId: string }>(
  module: { moduleType: string; subRoleIds?: string[] | undefined },
  subRoleModules: { subRoleIds: string[]; roleId: string }[]
) => (selectedNodes: T[]): T[] => {
  return module.moduleType === "SubRolePickSingle" && module.subRoleIds?.length
    ? pipe(
        module.subRoleIds,
        A.reduce(selectedNodes, (acc, siblingSubRoleId) =>
          pipe(acc, removeSubRole(subRoleModules, siblingSubRoleId))
        )
      )
    : selectedNodes;
};

export const getTextFromDictWithoutAccent = (lang: string) => (
  dict: Record<string, string>
): string | null =>
  pipe(
    dict[lang],
    O.fromNullable,
    O.map(x => x.replaceAll("$ACCENT$", "")),
    O.getOrElseW(() => null)
  );

export const getTextFromHint = (v: {
  hint: StepperModuleHint | null;
  lang: string;
}): string | null => {
  const getText = flow(
    getTextFromDictWithoutAccent(v.lang),
    O.fromNullable,
    O.getOrElseW(() => "")
  );

  return pipe(
    v.hint,
    O.fromNullable,
    O.map(h => getText(h.accentText) + getText(h.mainText)),
    O.chain(O.fromPredicate(x => x.trim().length > 0)),
    O.getOrElseW(() => null)
  );
};

export const getRoleSpecificityRows = (v: {
  lang: string;
  allModules: RobotStepperModule[];
  roleIsSpecificText: string;
  roleIsNotSpecificText: string;
  answers: {
    roleIsSpecific: boolean;
    moduleId: string;
  }[];
}): { question: string; answer: string; moduleId: string }[] =>
  pipe(
    v.answers,
    A.map(
      getRoleSpecificityRow(
        v.lang,
        v.allModules,
        v.roleIsNotSpecificText,
        v.roleIsNotSpecificText
      )
    ),
    A.compact
  );

const getRoleSpecificityRow = (
  lang: string,
  allModules: RobotStepperModule[],
  roleIsSpecificText: string,
  roleIsNotSpecificText: string
) => (v: {
  roleIsSpecific: boolean;
  moduleId: string;
}): O.Option<{ question: string; answer: string; moduleId: string }> => {
  return pipe(
    v.moduleId,
    getModuleByIdFromModules(
      pipe(allModules, filterByModuleType("RoleSpecificity"))
    ),
    O.map(module => {
      const getText = (dict: Record<string, string> | undefined) =>
        dict ? getTextFromDictWithoutAccent(lang)(dict) : null;

      return {
        question: getText(module.subTitle) ?? "",
        answer: v.roleIsSpecific
          ? getText(module.roleIsSpecificText) ?? roleIsSpecificText
          : getText(module.roleIsNotSpecificText) ?? roleIsNotSpecificText,
        moduleId: v.moduleId,
      };
    })
  );
};

export const getInitialRobotObjectWithSubRoles = (v: {
  recruitmentId: string;
  allModules: RobotStepperModule[];
  lang: string;
  selectedSubRoles: SubRoleStepValue[];
}): SourcingRobotRequirementsObject => {
  const defualtReqProfile = getRobotRequirementProfile(
    [],
    v.allModules,
    v.lang
  );

  const defaultRequirements = getRobotRequirements(
    { ...defualtReqProfile, selectedSubRoles: v.selectedSubRoles },
    {
      staffIsIncluded: false,
    }
  );

  return {
    isManual: false,
    isStopped: true,
    recruitmentId: v.recruitmentId,
    shouldBeRunInitially: false,
    requirements: defaultRequirements,
  };
};

export const getRecListRobotStatuses = (
  v: Pick<SourcingRobotRequirementsObject, "isStopped" | "isManual">
): {
  newRobotIsActive: boolean;
  manualRobotIsActive: boolean;
} => {
  return {
    newRobotIsActive: !v.isStopped && !v.isManual,
    manualRobotIsActive: !v.isStopped && v.isManual,
  };
};

const getCommonnessScore = (
  id: string,
  top100List: string[],
  map: {
    first: { top: number; score: number };
    second: { top: number; score: number };
    third: { top: number; score: number };
    fourth: { score: number };
  }
): number => {
  const index = top100List.findIndex(x => x === id);

  if (index <= map.first.top) {
    return map.first.score;
  }

  if (index <= map.second.top) {
    return map.second.score;
  }

  if (index <= map.third.top) {
    return map.third.score;
  }

  return map.fourth.score;
};

export const getOpennessScore = (v: {
  reqs: SourcingRobotRequirement[];
  baseInfo: JobbOfferBaseInfo | null;
  allModules: RobotStepperModule[];
  settingsFromDb: OpennessSettings | null;
  rolesWithSkillsAndTasksByToleId: Record<
    string,
    ThingToLoad<GetRoleWithSkillsAndTasksDto>
  >;
  allRoles: GetRolesDto[];
}): number => {
  const reqProfile = getRobotRequirementProfile(v.reqs, v.allModules, "en");

  const settings: OpennessSettings = v.settingsFromDb ?? {
    experienceYears: {
      weight: 1,
    },
    education: {
      devider: 5,
      weight: 1,
    },
    roleSpecificity: {
      weight: 0.4,
    },
    staff: {
      weight: 0.4,
    },
    tasks: {
      weight: 1,
      first: {
        top: 5,
        score: 0.1,
      },
      second: {
        top: 10,
        score: 0.1,
      },
      third: {
        top: 20,
        score: 0.2,
      },
      fourth: {
        score: 0.4,
      },
    },
    lang: {
      weight: 1,
      power: 1.5,
      en: 0,
      sv: 0.1,
      other: 1,
    },
    skills: {
      weight: 1,
      first: {
        top: 20,
        score: 0.1,
      },
      second: {
        top: 50,
        score: 0.3,
      },
      third: {
        top: 100,
        score: 0.7,
      },
      fourth: {
        score: 2,
      },
    },
    remote: {
      weight: 1,
      allWeekAndFullRemote: 0,
      allWeek: 0.1,
      fullRemote: 0.1,
      partOfWeek: 0.2,
      notAllowed: 0.5,
    },
    salary: {
      weight: 1,
      bonusWeight: 0.5,
      scorePerStep: 0.15,
    },
  };

  const experienceYearsScore = () => {
    const req = reqProfile.yearsExperienceRange;

    const highestMax = 20;

    const max = req.max ?? highestMax;
    const min = req.min;

    const diff = max - min;

    return (1 - diff / highestMax) * (settings.experienceYears.weight ?? 1);
  };

  const educationScore = () => {
    const minYears = reqProfile.minimunYearsOfEducation;

    return (minYears / settings.education.devider) * settings.education.weight;
  };

  const roleSpecificityScore = () => {
    const req = reqProfile.roleSpecificityAnswers;

    const roleIsSpecific = req.some(x => x.roleIsSpecific);

    return roleIsSpecific ? settings.roleSpecificity.weight ?? 0.4 : 0;
  };

  const staffScore = () => {
    const isRequired =
      reqProfile.requireStaffResponsibility?.type === "required";

    return isRequired ? settings.staff.weight ?? 0.4 : 0;
  };

  const taskScore = () => {
    return pipe(
      O.fromNullable(v.baseInfo?.roleId),
      O.chain(tryGetThingFromRecord(v.rolesWithSkillsAndTasksByToleId)),
      O.map(role =>
        reqProfile.selectedRequiredTasks.map(x =>
          getCommonnessScore(x.taskId, role.tasksTop100, settings.tasks)
        )
      ),
      O.map(xs =>
        pipe(
          xs,
          A.reduce(0, (acc, x) => acc + x),
          sum => sum * xs.length * (settings.tasks.weight ?? 1)
        )
      ),
      O.getOrElseW(() => 0)
    );
  };

  const skillScore = () => {
    return pipe(
      O.fromNullable(v.baseInfo?.roleId),
      O.chain(tryGetThingFromRecord(v.rolesWithSkillsAndTasksByToleId)),
      O.map(role =>
        reqProfile.selectedSkillGroups.map(x =>
          pipe(
            x.skillIds,
            A.map(skillId =>
              getCommonnessScore(skillId, role.skillsTop100, settings.skills)
            ),
            A.reduce({} as Record<number, number[]>, (acc, x) => {
              const key = x;
              const value = acc[x] ?? [];
              return {
                ...acc,
                [key]: [...value, x],
              };
            }),
            x => Object.values(x),
            A.filter(A.isNonEmpty),
            sortListBy([
              {
                sortBy: x => x[0],
              },
            ]),
            A.head,
            O.map(x => x[0] / x.length),
            O.getOrElseW(() => 0)
          )
        )
      ),
      O.map(xs =>
        pipe(
          xs,
          A.reduce(0, (acc, x) => acc + x),
          sum => sum * xs.length * (settings.skills.weight ?? 1)
        )
      ),
      O.getOrElseW(() => 0)
    );
  };

  const languageScore = () => {
    const langIds = reqProfile.requiredLanguageIds;
    const commonness = (langId: string) =>
      langId === "5035c3d7-77d2-43ff-9c35-d979865b6ff8" // engelska
        ? settings.lang.en
        : langId === "b5a5356a-e1f3-4772-9c20-b49acc5a4a92" // svenska
        ? settings.lang.sv
        : settings.lang.other;

    return pipe(
      langIds,
      A.map(commonness),
      A.reduce(0, (acc, x) => acc + x),
      sum => {
        const lengthSquared = langIds.length ** settings.lang.power;

        return sum * (lengthSquared / 2) * (settings.lang.weight ?? 1);
      }
    );
  };

  const remoteScore = () => {
    const baseInfo = v.baseInfo;
    if (baseInfo === null) {
      return 0;
    }
    const wfh = baseInfo.workFromHome ?? WorkFromHome.FourDays;
    return (
      (wfh === WorkFromHome.AllWeek && baseInfo.acceptsFullRemote
        ? settings.remote.allWeekAndFullRemote
        : wfh === WorkFromHome.AllWeek
        ? settings.remote.allWeek
        : baseInfo.isFullRemote
        ? settings.remote.fullRemote
        : [
            WorkFromHome.OneDay,
            WorkFromHome.TwoDays,
            WorkFromHome.ThreeDays,
            WorkFromHome.FourDays,
          ].includes(wfh)
        ? settings.remote.partOfWeek
        : settings.remote.notAllowed) * (settings.remote.weight ?? 1)
    );
  };

  const salaryScore = (allRoles: GetRolesDto[]) => {
    return () => {
      const baseInfo = v.baseInfo;
      if (baseInfo === null) {
        return 0;
      }

      const salary =
        reqProfile.salaryObject.baseSalary +
        (reqProfile.salaryObject.bonus ?? 0) * settings.salary.bonusWeight;

      if (salary === 0) {
        return 0;
      }
      const role = allRoles.find(x => x.id === baseInfo.roleId);

      const averageSalary = role?.salaryData?.salaryAverage ?? 55000;
      const salaryStep = Math.max(role?.salaryData?.step ?? 5000, 1);

      const top = averageSalary + salaryStep * 3;

      if (salary >= top) {
        return 0;
      }

      const stepsFromTop = (top - salary) / salaryStep;

      return (
        stepsFromTop *
        settings.salary.scorePerStep *
        (settings.salary.weight ?? 1)
      );
    };
  };

  return pipe(
    getScoreLazy([
      staffScore,
      experienceYearsScore,
      educationScore,
      roleSpecificityScore,
      languageScore,
      remoteScore,
      salaryScore(v.allRoles),
      taskScore,
      skillScore,
    ]),
    getNumberRounded(2)
  );
};

const getScoreLazy = (arr: (() => number)[]): number => {
  return pipe(
    arr,
    A.reduce(0, (acc, x) => (acc >= 100 ? acc : acc + x()))
  );
};

export const maxForExperienceRange = 21;

export const getNewBaseInfoWithAppliedTip = (
  oldBaseInfo: JobbOfferBaseInfo,
  tip: WantMoreCandidatesTip
): JobbOfferBaseInfo => {
  switch (tip.subType) {
    case "RemoteAcceptFullRemote":
      return {
        ...oldBaseInfo,
        acceptsFullRemote: true,
      };
    case "RemoteMoreFlexible":
      return {
        ...oldBaseInfo,
        workFromHome: WorkFromHome.FourDays,
      };
    case "DecreaseEducation":
    case "DecreaseMinExperienceYears":
    case "IncreaseMaxExperienceYears":
    case "Language":
    case "RemoveOneSkill":
    case "RemoveSkillGroup":
    case "ReplaceWithSkillGroup":
    case "RoleSpecificity":
    case "SalaryIncrease":
    case "Staff":
    case "Task":
      return oldBaseInfo;
  }
};

export const getNewRequirementsProfileWithAppliedTip = (
  oldProfile: RobotRequirementProfileDto,
  allModules: RobotStepperModule[],
  lang: string
) => (tip: WantMoreCandidatesTip): RobotRequirementProfileDto => {
  switch (tip.subType) {
    case "DecreaseEducation":
      return {
        ...oldProfile,
        minimunYearsOfEducation: tip.newEducationYears,
      };
    case "DecreaseMinExperienceYears":
      return {
        ...oldProfile,
        yearsExperienceRange: {
          ...oldProfile.yearsExperienceRange,
          min: tip.newMinYears,
        },
      };
    case "IncreaseMaxExperienceYears":
      return {
        ...oldProfile,
        yearsExperienceRange: {
          ...oldProfile.yearsExperienceRange,
          max: tip.newMaxYears,
        },
      };
    case "Language":
      return {
        ...oldProfile,
        requiredLanguageIds: oldProfile.requiredLanguageIds.filter(
          x => x !== tip.languageId
        ),
      };

    case "RemoveOneSkill":
      return {
        ...oldProfile,
        selectedSkillGroups: oldProfile.selectedSkillGroups.filter(
          x =>
            !pipe(
              x,
              isEqualToSkillGroup({
                skillIds: [tip.skillId],
              })
            )
        ),
      };
    case "RemoveSkillGroup":
      return {
        ...oldProfile,
        selectedSkillGroups: oldProfile.selectedSkillGroups.filter(
          x => !pipe(x, isEqualToSkillGroup(tip))
        ),
      };
    case "ReplaceWithSkillGroup": {
      const activeModules = allModules.filter(isActiveModule);
      const getModuleById = getModuleByIdFromModules(
        activeModules.filter(hasModuleId)
      );
      const module = pipe(
        getModuleById(tip.moduleId),
        O.getOrElseW(() => null)
      );

      if (module === null) {
        return oldProfile;
      }
      return {
        ...oldProfile,
        selectedSkillGroups: oldProfile.selectedSkillGroups
          .filter(
            x =>
              !pipe(
                x,
                isEqualToSkillGroup({
                  skillIds: [tip.skillId],
                })
              )
          )
          .concat([
            {
              groupName: tip.groupName?.[lang] ?? null,
              skillIds: tip.skillIds,
              moduleId: tip.moduleId,
              module,
            },
          ]),
      };
    }
    case "RoleSpecificity": {
      return {
        ...oldProfile,
        roleSpecificityAnswers: oldProfile.roleSpecificityAnswers.map(x => ({
          ...x,
          roleIsSpecific: false,
        })),
      };
    }
    case "SalaryIncrease": {
      return {
        ...oldProfile,
        salaryObject: {
          ...oldProfile.salaryObject,
          baseSalary: tip.newSalary,
        },
      };
    }
    case "Staff": {
      return {
        ...oldProfile,
        requireStaffResponsibility:
          oldProfile.requireStaffResponsibility.type === "required"
            ? {
                type: "notRequired",
                moduleId: oldProfile.requireStaffResponsibility.moduleId,
              }
            : {
                type: "notAnswered",
              },
      };
    }
    case "Task": {
      return {
        ...oldProfile,
        selectedRequiredTasks: oldProfile.selectedRequiredTasks.filter(
          x => x.taskId !== tip.taskId
        ),
      };
    }
    case "RemoteAcceptFullRemote":
    case "RemoteMoreFlexible":
      return oldProfile;
  }
};

const listsAreEqual = (a: string[], b: string[]): boolean =>
  arraysAreEqual(a, b, (a, b) => a === b);

export const tipIsEqual = (
  a: WantMoreCandidatesTip,
  b: WantMoreCandidatesTip
): boolean => {
  switch (a.subType) {
    case "DecreaseEducation":
      return (
        b.subType === a.subType && a.newEducationYears === b.newEducationYears
      );
    case "DecreaseMinExperienceYears":
      return b.subType === a.subType && a.newMinYears === b.newMinYears;
    case "IncreaseMaxExperienceYears":
      return b.subType === a.subType && a.newMaxYears === b.newMaxYears;
    case "Language":
      return b.subType === a.subType && a.languageId === b.languageId;
    case "RemoteAcceptFullRemote":
      return b.subType === a.subType;
    case "RemoteMoreFlexible":
      return b.subType === a.subType;
    case "RemoveOneSkill":
      return b.subType === a.subType && b.skillId === a.skillId;
    case "RemoveSkillGroup":
      return b.subType === a.subType && listsAreEqual(a.skillIds, b.skillIds);
    case "ReplaceWithSkillGroup":
      return (
        b.subType === a.subType &&
        b.skillId === a.skillId &&
        listsAreEqual(a.skillIds, b.skillIds)
      );
    case "RoleSpecificity":
      return b.subType === a.subType;
    case "SalaryIncrease":
      return b.subType === a.subType && b.newSalary === a.newSalary;
    case "Staff":
      return b.subType === a.subType;
    case "Task":
      return b.subType === a.subType && b.taskId === a.taskId;
  }
};
