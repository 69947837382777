// import uuidv1 from "uuid/v1";
import axios from "axios";
import { ListObjectNullable } from "~/models/ListObjectNullable";
import { GetTeamTailorTalentIdsDto } from "~/models/types";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default class CompanySettingsService {
  public static updateBlockedCompaniesForSourcing(v: {
    companies: ListObjectNullable[];
    accessToken: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateBlockedCompaniesForSourcing`,
        {
          companies: v.companies,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getTeamTailorTalentIds(v: {
    accessToken: string;
  }): Promise<GetTeamTailorTalentIdsDto[]> {
    return axios
      .get<GetTeamTailorTalentIdsDto[]>(
        `${gritifyApiUrl}/GetTeamTailorTalentIds`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static updateCompanyEmail(v: {
    email: string | null;
    accessToken: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateCompanyEmail`,
        {
          email: v.email,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static addTalentIdToBlockedTalentIds(v: {
    talentId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/AddTalentIdToBlockedTalentIds`,
        {
          talentId: v.talentId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static setCompanyGDPRTexts(v: {
    accessToken: string;
    textDict: Record<string, string>;
    useCustomTexts: boolean;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/SetCompanyGDPRTexts`,
        {
          textDict: v.textDict,
          useCustomTexts: v.useCustomTexts,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }
}
