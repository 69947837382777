// import uuidv1 from "uuid/v1";
import axios from "axios";
import {
  Office,
  OfficeToCreate,
  OfficeToUpdate,
  UpsertOfficeResponseDto,
} from "~/models/types";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default class OfficeService {
  public static createOffice(
    office: OfficeToCreate,
    accessToken: string
  ): Promise<UpsertOfficeResponseDto> {
    return axios
      .post<UpsertOfficeResponseDto>(`${gritifyApiUrl}/CreateOffice`, office, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then(x => x.data);
  }

  public static updateOffice(
    office: OfficeToUpdate,
    accessToken: string
  ): Promise<UpsertOfficeResponseDto> {
    return axios
      .put<UpsertOfficeResponseDto>(`${gritifyApiUrl}/UpdateOffice`, office, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then(x => x.data);
  }

  public static getOffices(accessToken: string): Promise<Office[]> {
    return axios
      .get<Office[]>(`${gritifyApiUrl}/GetOffice`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then(x => x.data);
  }
}
