




















































































































































































































































































































































































































































































import { Vue, Component } from "nuxt-property-decorator";
import Confirm from "../components/Confirm.vue";
import {
  authStore,
  companyBaseStore,
  companyProfileStore,
  talentPoolStore,
} from "../store";
import CompanySuperSearch from "~/components/CompanySuperSearch.vue";
import { ListObject } from "~/models/ListObject";
import SortHelper from "~/helpers/sortHelper";
import IsOnIEModal from "~/components/IsOnIEModal.vue";
import RecruitmentPickerMenu from "~/components/jobbOffer/RecruitmentPickerMenu.vue";
import GrScrollPanel from "~/components/styling/GrScrollPanel.vue";
import NotificationsList from "~/components/company/NotificationsList.vue";
import ConversationDrawerContent from "~/components/ConversationDrawerContent.vue";
import CompanyNotificationDrawer from "~/components/CompanyNotificationDrawer.vue";
import GrNavLinkDense from "~/components/common/GrNavLinkDense.vue";
import TalentPoolHelpers from "~/helpers/TalentPoolHelpers";
import GrButton from "~/components/common/GrButton.vue";
import StartAccessSuccessModal from "~/components/company/StartAccessSuccessModal.vue";

@Component({
  components: {
    Confirm,
    CompanySuperSearch,
    IsOnIEModal,
    RecruitmentPickerMenu,
    GrScrollPanel,
    NotificationsList,
    ConversationDrawerContent,
    CompanyNotificationDrawer,
    GrNavLinkDense,
    GrButton,
    StartAccessSuccessModal,
  },
  middleware: [
    "authenticatedCompany",
    "fixLocaleCompany",
    async function() {
      await Promise.all([
        companyProfileStore.loadPublicProfile(),
        companyProfileStore.loadProfile(),
      ]);
    },
  ],
})
export default class CompanyLayout extends Vue {
  drawer = false;
  isHydrated = false;
  rigthDrawerOpen = false;
  conversationDraweOpen = false;

  get hasFullAccess(): boolean {
    return authStore.hasFullAccess;
  }

  get isFreeAccount() {
    return companyBaseStore.isFreeAccount;
  }

  openNotifications() {
    this.rigthDrawerOpen = true;
    companyBaseStore.loadNotifications();
    companyBaseStore.setNotificationsSeen();
    companyBaseStore.loadGritifyNews();
  }

  get canViewCompanyProfile(): boolean {
    return (
      authStore.userAccess === "Full" || authStore.userAccess === "SuperAdmin"
    );
  }

  get canViewTalentPool(): boolean {
    return TalentPoolHelpers.canAccessTalentPool();
  }

  get recruitmentId(): string | null {
    return this.$route.params.recruitmentId ?? null;
  }

  get isDisconnectedFromSignalR(): boolean {
    return companyBaseStore.isDisconnected;
  }

  get numberOfUnseenNotifications(): number {
    return (
      companyBaseStore.numberOfUnseenNotificationsByCompanyId[this.companyId] ??
      0
    );
  }

  openTalentConversations() {
    companyBaseStore.loadTalentConversations();
    this.conversationDraweOpen = true;
  }

  get logoImageUrl(): string | null {
    return companyProfileStore.profile.logoUrl;
  }

  get numberOfUnreadTalentConversations(): number {
    return companyBaseStore.talentConversationsUnreadCount;
  }

  reloadSite() {
    location.reload();
  }

  ignoredDisconnected = true;

  cancelReloadPage() {
    this.ignoredDisconnected = true;
  }

  inputsExpanded = false;

  gritifyInputsExpandToggled() {
    this.inputsExpanded = !this.inputsExpanded;
  }

  get numberOfUnseenNotificationsOnOtherCompanies(): number {
    return this.companies.reduce((acc: number, c) => {
      if (c.id !== this.companyId) {
        return acc + c.numberOfUnseenNotification;
      }

      return acc;
    }, 0);
  }

  get showAllTalentsInsteadOfPool(): boolean {
    return talentPoolStore.showAllTalents;
  }

  get talentPoolTitle() {
    return this.showAllTalentsInsteadOfPool
      ? this.$t("Kandidater")
      : this.$t("kandidatpoolen");
  }

  get companies(): (ListObject & { numberOfUnseenNotification: number })[] {
    return SortHelper.sortItemsBy(
      companyBaseStore.userDetails.companies.map(c => {
        return {
          ...c,
          numberOfUnseenNotification:
            c.id === this.companyId
              ? 0
              : companyBaseStore.numberOfUnseenNotificationsByCompanyId[c.id] ??
                0,
        };
      }),
      [
        { sortBy: m => m.numberOfUnseenNotification, desc: true },
        { sortBy: m => m.text },
      ]
    );
  }

  loadingCompanySwitch = false;

  get isDoneWithCompanyStepper(): boolean {
    const showStepper =
      companyBaseStore.companyDetailsObject?.showNewCompanyStepper ?? false;
    return !showStepper;
  }

  get companyName(): string {
    const company = this.companies.find(x => x.id === this.logginCompanyId);
    return company ? company.text : "";
  }

  get companyId(): string {
    return authStore.companyId!;
  }

  get logginCompanyId(): string | null {
    return authStore.companyId;
  }
  // set logginCompanyId(v: string | null) {
  //   if (v) {
  //     this.changeCompany(v);
  //   }
  //   // this.$msal.signIn({
  //   //   forceRefresh: true,
  //   //   scopes: [
  //   //     process.env.ENVIRONMENT === "Production"
  //   //       ? "https://gritifyauth.onmicrosoft.com/api/gritify"
  //   //       : process.env.ENVIRONMENT === "Stage"
  //   //       ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
  //   //       : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
  //   //   ],
  //   //   extraQueryParameters: {
  //   //     policy_type: "Login",
  //   //     ui_locales: this.$i18n.locale,
  //   //     companyId: v,
  //   //   },
  //   // });
  // }

  changeCompany(companyId: string) {
    location.href = this.localePath({
      name: "company-companyId",
      params: {
        companyId,
      },
    });
    // try {
    //   // this.$router.push(
    //   //   this.localePath({
    //   //     name: "company",
    //   //   })
    //   // );
    //   this.loadingCompanySwitch = true;
    //   const newToken = await this.$msal.acquireToken({
    //     forceRefresh: true,
    //     scopes: [
    //       process.env.ENVIRONMENT === "Production"
    //         ? "https://gritifyauth.onmicrosoft.com/api/gritify"
    //         : process.env.ENVIRONMENT === "Stage"
    //         ? "https://gritifyauthstage.onmicrosoft.com/api/gritify"
    //         : "https://gritifytalentauthtest.onmicrosoft.com/api/gritify",
    //     ],
    //     extraQueryParameters: {
    //       policy_type: "Login",
    //       ui_locales: this.$i18n.locale,
    //       companyId,
    //     },
    //   });

    //   if (typeof newToken === "string") {
    //     authStore.SET_ACCESS_TOKEN(newToken);
    //   }

    //   location.href = this.localePath({
    //     name: "company",
    //     query: {
    //       companyId,
    //     },
    //   });

    //   // this.reloadSite();
    // } catch (error) {
    //   notificationsStore.setErrorMessage({ error });
    // } finally {
    //   this.loadingCompanySwitch = false;
    // }
  }

  parseJwt(token: string) {
    const base64Url = token.split(".")[1] ?? token;
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  get isMobile() {
    if (!this.isHydrated) {
      return false;
    }
    const breakPointName = this.$vuetify.breakpoint.name;
    return breakPointName === "xs";
  }

  get isIe() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    const msie11 = ua.indexOf("Trident/");
    const isIE = msie > 0 || msie11 > 0;
    return isIE;
  }

  $refs!: Vue["$refs"] & {
    confirm: Confirm;
    IsOnIEModal?: IsOnIEModal;
    StartAccessSuccessModal: StartAccessSuccessModal;
  };

  openStartAccessSuccessModal() {
    this.$refs.StartAccessSuccessModal.open();
  }

  get companyComponentsMenuItems(): {
    text: string;
    isActive: boolean;
    url: string;
  }[] {
    const routeName = this.$route.name;
    if (!routeName) {
      return [];
    }
    return [
      {
        text: this.$t("Företagsprofil").toString(),
        isActive: routeName.startsWith("company-companyId-companyProfile"),
        url: this.localePath({
          name: "company-companyId-companyProfile",
          params: {
            companyId: this.companyId,
          },
        }),
      },
      {
        text: this.$t("Kontor").toString(),
        isActive: routeName.startsWith("company-companyId-offices"),
        url: this.localePath({
          name: "company-companyId-offices",
          params: {
            companyId: this.companyId,
          },
        }),
      },
      {
        text: this.$t("Avdelningar").toString(),
        isActive: routeName.startsWith("company-companyId-teams"),
        url: this.localePath({
          name: "company-companyId-teams",
          params: {
            companyId: this.companyId,
          },
        }),
      },
      {
        text: this.$t("Medarbetare").toString(),
        isActive: routeName.startsWith("company-companyId-coworkers"),
        url: this.localePath({
          name: "company-companyId-coworkers",
          params: {
            companyId: this.companyId,
          },
        }),
      },
    ];
  }

  get showCompanyComponentsMenu(): boolean {
    return this.companyComponentsMenuItems.some(x => x.isActive);
  }

  mounted() {
    (this.$root as any).$confirm = (this.$refs.confirm as Confirm).open;
    this.isHydrated = true;

    if (this.isIe) {
      this.$refs.IsOnIEModal?.open();
    }
  }

  logout() {
    this.$msal.signOut();
  }
}
