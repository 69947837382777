import axios from "axios";
import { CoWorkerDto, CoWokerToAdd } from "~/models/types";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default {
  getCoWorkers(accessToken: string): Promise<CoWorkerDto[]> {
    return axios
      .get<CoWorkerDto[]>(`${gritifyApiUrl}/GetCoWorker`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data.map(cw => ({
          ...cw,
        }));
      });
  },
  createCoWorker(coWorker: CoWokerToAdd, accessToken: string): Promise<string> {
    return axios
      .post<string>(`${gritifyApiUrl}/CreateCoWorker`, coWorker, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },
  updateCoWorker(coWorker: CoWorkerDto, accessToken: string): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateCoWorker?coWorkerId=${coWorker.id}`,
        coWorker,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
};
