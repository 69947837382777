




















































































import { Vue, Component, Prop } from "vue-property-decorator";
import TalentModalWrapper from "./common/TalentModalWrapper.vue";
import { authStore, companyBaseStore, notificationsStore } from "~/store";

export type UpgradeToAccessSuccessCustomerModalResult =
  | {
      type: "Saved";
    }
  | {
      type: "Cancel";
    };

@Component({
  components: {
    TalentModalWrapper,
  },
})
export default class UpgradeToAccessSuccessCustomerModal extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isEditing!: boolean;

  dialog = false;
  resolve:
    | ((v: UpgradeToAccessSuccessCustomerModalResult) => void)
    | null = null;

  get requiredRules() {
    return [(v: string) => !!v || this.$t("Obligatoriskt")];
  }

  get emailRules() {
    return [
      (v: string) =>
        /.+@.+\..+/.test(v) || this.$t("E-postadressen är ogiltig").toString(),
    ];
  }

  email: string | null = null;
  name: string | null = null;
  orgNumber: string | null = null;

  open(): Promise<UpgradeToAccessSuccessCustomerModalResult> {
    this.dialog = true;
    const info = companyBaseStore.companyDetailsObject?.accessSuccessInfo;

    this.email = info?.invoiceEmail ?? (authStore.companyUser?.email || null);
    this.name =
      info?.contactName ?? authStore.companyUser?.firstName
        ? `${authStore.companyUser?.firstName} ${authStore.companyUser?.lastName}`
        : null;
    this.orgNumber = info?.orgNumber ?? null;

    this.$refs.editForm && this.$refs.editForm.resetValidation();

    return new Promise<UpgradeToAccessSuccessCustomerModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  $refs!: Vue["$refs"] & {
    editForm: any;
  };

  cancel() {
    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.dialog = false;
  }

  loadingSave = false;

  async save() {
    if (this.$refs.editForm.validate()) {
      if (this.resolve) {
        try {
          this.loadingSave = true;

          await companyBaseStore.upgradeCompanyToAccessSuccess({
            contactName: this.name!,
            invoiceEmail: this.email!,
            orgNumber: this.orgNumber!,
          });
          notificationsStore.setSuccessMessage(
            this.$t(
              "Tack! Ni är nu uppgraderade till Gritify Access. Vi kommer att skicka en faktura till er angivna e-postadress."
            ).toString()
          );

          this.resolve({
            type: "Saved",
          });
          this.dialog = false;
        } catch (error) {
          notificationsStore.setErrorMessage({ error });
        } finally {
          this.loadingSave = false;
        }
      }
    }
  }
}
