


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";

export type TermsAndDebitModelModalResult = {
  type: "Cancel";
};

@Component
export default class TermsAndDebitModelModal extends Vue {
  dialog = false;
  resolve: ((v: TermsAndDebitModelModalResult) => void) | null = null;

  open(): Promise<TermsAndDebitModelModalResult> {
    this.dialog = true;

    return new Promise<TermsAndDebitModelModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  $refs!: Vue["$refs"] & {};

  cancel() {
    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.dialog = false;
  }
}
