






















































































































































































































































import { Vue, Component } from "vue-property-decorator";
import GrButton from "../common/GrButton.vue";
import CommonQuestion from "../common/CommonQuestion.vue";
import UpgradeToAccessSuccessCustomerModal from "../UpgradeToAccessSuccessCustomerModal.vue";
import CommonQuestionsPricingList from "../common/CommonQuestionsPricingList.vue";
import TextWrapperReplacer from "./robotStepperModules/TextWrapperReplacer.vue";
import TermsAndDebitModelModal from "./TermsAndDebitModelModal.vue";
import { companyBaseStore } from "~/store";

export type StartAccessSuccessModalResult =
  | {
      type: "Saved";
    }
  | {
      type: "Cancel";
    };

@Component({
  components: {
    GrButton,
    TextWrapperReplacer,
    TermsAndDebitModelModal,
    CommonQuestion,
    UpgradeToAccessSuccessCustomerModal,
    CommonQuestionsPricingList,
  },
})
export default class StartAccessSuccessModal extends Vue {
  dialog = false;
  resolve: ((v: StartAccessSuccessModalResult) => void) | null = null;

  open(): Promise<StartAccessSuccessModalResult> {
    this.dialog = true;

    return new Promise<StartAccessSuccessModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  get accessFeeMonthly() {
    return companyBaseStore.accessFee;
  }

  acceptsTerms = false;

  question1Open = false;
  question2Open = false;
  question3Open = false;
  question4Open = false;

  openTermsModal() {
    this.$refs.TermsAndDebitModelModal.open();
  }

  $refs!: Vue["$refs"] & {
    TermsAndDebitModelModal: TermsAndDebitModelModal;
    UpgradeToAccessSuccessCustomerModal: UpgradeToAccessSuccessCustomerModal;
  };

  async openUpgradeToAccessSuccessCustomerModal() {
    const result = await this.$refs.UpgradeToAccessSuccessCustomerModal.open();

    if (result.type === "Saved") {
      if (this.resolve) {
        this.resolve({
          type: "Saved",
        });
      }
      this.dialog = false;
    }
  }

  cancel() {
    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.dialog = false;
  }

  save() {
    if (this.resolve) {
      this.resolve({
        type: "Saved",
      });
    }
    this.dialog = false;
  }
}
