import { render, staticRenderFns } from "./companyLayout.vue?vue&type=template&id=d1dfbd88&scoped=true&"
import script from "./companyLayout.vue?vue&type=script&lang=ts&"
export * from "./companyLayout.vue?vue&type=script&lang=ts&"
import style0 from "./companyLayout.vue?vue&type=style&index=0&lang=css&"
import style1 from "./companyLayout.vue?vue&type=style&index=1&id=d1dfbd88&scoped=true&lang=scss&"
import style2 from "./companyLayout.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1dfbd88",
  null
  
)

/* custom blocks */
import block0 from "./companyLayout.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Clayouts%5CcompanyLayout.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VApp,VAppBar,VBadge,VBtn,VDivider,VIcon,VImg,VList,VListItem,VListItemIcon,VListItemTitle,VMain,VMenu,VNavigationDrawer,VSpacer})
