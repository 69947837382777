import axios from "axios";
import {
  ImageEntityType,
  GetLibraryPhotoDto,
  RecruitmentImagePackObject,
  RecruitmentImagePackToAdd,
} from "~/models/types";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default class CompanyPhotosService {
  static deleteImage(fileName: string, accessToken: string): Promise<void> {
    return axios
      .delete(`${gritifyApiUrl}/DeleteImage?uniqueName=${fileName}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  static undeleteImage(fileName: string, accessToken: string): Promise<void> {
    return axios
      .delete(`${gritifyApiUrl}/UnDeleteImage?uniqueName=${fileName}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  static uploadImage(file: any, accessToken: string): Promise<string> {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return axios
      .post(`${gritifyApiUrl}/UploadImageLibrary`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  static uploadImageToEntity(v: {
    file: any;
    token: string;
    entityType: ImageEntityType;
  }): Promise<string> {
    const formData = new FormData();
    formData.append("file", v.file, v.file.name);

    return axios
      .put<string>(
        `${gritifyApiUrl}/UploadImage?type=${v.entityType}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static getPhotosByCompanyId(
    accessToken: string
  ): Promise<
    {
      url: string;
      fileName: string;
      uniqueName: string;
      deleted: Date | null;
    }[]
  > {
    return axios
      .get<GetLibraryPhotoDto[]>(`${gritifyApiUrl}/GetImageLibrary`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data.reduce(
          (
            acc: {
              url: string;
              fileName: string;
              uniqueName: string;
              deleted: Date | null;
            }[],
            p
          ) => {
            if (!p.uniqueName) {
              return acc;
            }
            return [
              ...acc,
              {
                ...p,
                deleted: p.deleted ? new Date(p.deleted) : null,
                uniqueName: p.uniqueName || "",
              },
            ];
          },
          []
        );
      });
  }

  static getRecruitmentImagePacks(
    accessToken: string
  ): Promise<RecruitmentImagePackObject[]> {
    return axios
      .get<RecruitmentImagePackObject[]>(
        `${gritifyApiUrl}/GetRecruitmentImagePacks`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }

  static upsertRecruitmentImagePack(v: {
    accessToken: string;
    imagePack: RecruitmentImagePackToAdd | RecruitmentImagePackObject;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/UpsertRecruitmentImagePack`,
        v.imagePack,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  }
}
