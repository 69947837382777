import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import * as signalR from "@microsoft/signalr";
import {
  NotificationItem,
  LastVisitedItem,
  LastVisitedItemToAdd,
  GritifyNewsItem,
  UserDetails,
  SingalRMessage,
  CompanyObject,
  GetTeamTailorTalentIdsDto,
  TalentConversations,
  CompanyTalentGetDto,
  ThingToLoad,
  CompanyGDPRTexts,
  PricingModels,
} from "~/models/types";

import companyUserService from "~/services/companyUserService";
import { $msal } from "~/utils/msal";
import { companyBaseStore, jobbOfferStore } from "~/store";
import NotificationMapper from "~/helpers/notificationMapper";
import jobOfferService from "~/services/jobOfferService";
import SignalRHelper from "~/helpers/SignalRHelper";
import { authStore } from "~/utils/store-accessor";
import SortHelper from "~/helpers/sortHelper";
import TokenHelpers from "~/helpers/tokenHelpers";
import CompanySettingsService from "~/services/companySettingsService";
import { ListObjectNullable } from "~/models/ListObjectNullable";
import CompanyTalentService from "~/services/companyTalentService";
import FilterHelpers from "~/helpers/filterHelpers";
import {
  setCompanyLanguage,
  setDontShowATSTips,
  upgradeCompanyToAccessSuccess,
} from "~/services/companyService";
import LookupDataService from "~/services/lookupDataService";
import { getMonthlyAccessFee } from "~/helpers/RecruitmentHelperFunctions";

export const defaultAccessFeeYearly = 2995;

@Module({
  name: "companyBase",
  stateFactory: true,
  namespaced: true,
})
export default class CompanyBaseStore extends VuexModule {
  notifications: NotificationItem[] = [];
  notificationsPageSize = 15;
  loadMoreNotificationsToken: string | null = null;
  lastVisits: LastVisitedItem[] = [];
  gritifyNews: GritifyNewsItem[] = [];
  companyTalentsInternal: ThingToLoad<CompanyTalentGetDto[]> = {
    type: "notFetched",
  };

  get companyTalents(): CompanyTalentGetDto[] {
    return TokenHelpers.GetValueOrDefault(this.companyTalentsInternal, []);
  }

  get loadingCompanyTalents(): boolean {
    return this.companyTalentsInternal.type === "loading";
  }

  companyDetailsAreLoaded = false;

  showRemovedUsers = false;

  @Mutation
  SET_COMPANY_TALENTS(v: ThingToLoad<CompanyTalentGetDto[]>) {
    this.companyTalentsInternal = v;
  }

  @Action
  async loadCompanyTalent(v: { talentId: string }) {
    const loadedCompanyTalents = this.companyTalentsInternal;

    const companyTalents = TokenHelpers.GetValueOrDefault(
      loadedCompanyTalents,
      []
    );

    if (loadedCompanyTalents.type === "notFetched") {
      return;
    }

    const existingCompanyTalent = companyTalents.find(
      x => x.talentId === v.talentId
    );

    if (existingCompanyTalent) {
      return;
    }
    const token = await TokenHelpers.getToken();
    const companyTalent = await CompanyTalentService.getCompanyTalentGetDtoByTalentId(
      {
        talentId: v.talentId,
        token,
      }
    );

    if (companyTalent) {
      if (this.companyTalentsInternal.type !== "notFetched") {
        companyBaseStore.SET_COMPANY_TALENTS({
          ...this.companyTalentsInternal,
          value: FilterHelpers.mergeArrays(
            this.companyTalentsInternal.value ?? [],
            [companyTalent],
            x => x.talentId
          ),
        });
      }
    }
  }

  companyGDPRTexts: ThingToLoad<Pick<
    CompanyGDPRTexts,
    "textDict" | "useCustomTexts"
  > | null> = {
    type: "notFetched",
  };

  @Mutation
  SET_COMPANY_GDPR_TEXTS(
    v: ThingToLoad<Pick<CompanyGDPRTexts, "textDict" | "useCustomTexts"> | null>
  ) {
    this.companyGDPRTexts = v;
  }

  @Action
  async loadCompanyGDPRTexts() {
    const result = TokenHelpers.getThingToLoadOrExit(
      this.companyGDPRTexts,
      false
    );

    if (result.type === "exit") {
      return;
    }

    companyBaseStore.SET_COMPANY_GDPR_TEXTS(result);

    const companyGDPRTexts = await LookupDataService.getCompanyGDPRTexts({
      companyId: authStore.companyId!,
    });

    companyBaseStore.SET_COMPANY_GDPR_TEXTS({
      type: "loaded",
      value: companyGDPRTexts,
    });
  }

  @Action
  async setCompanyGDPRTexts(v: {
    textDict: Record<string, string>;
    useCustomTexts: boolean;
  }) {
    const token = await TokenHelpers.getToken();

    await CompanySettingsService.setCompanyGDPRTexts({
      accessToken: token,
      textDict: v.textDict,
      useCustomTexts: v.useCustomTexts,
    });

    companyBaseStore.SET_COMPANY_GDPR_TEXTS({
      type: "loaded",
      value: {
        textDict: v.textDict,
        useCustomTexts: v.useCustomTexts,
      },
    });
  }

  @Action
  async loadCompanyTalents() {
    const result = TokenHelpers.getThingToLoadOrExit(
      this.companyTalentsInternal,
      false
    );

    if (result.type === "exit") {
      return;
    }

    companyBaseStore.SET_COMPANY_TALENTS(result);

    const token = await TokenHelpers.getToken();

    const companyTalents = await CompanyTalentService.getCompanyTalentGetDtos({
      accessToken: token,
    });

    companyBaseStore.SET_COMPANY_TALENTS({
      type: "loaded",
      value: FilterHelpers.mergeArrays(
        TokenHelpers.GetArrayFrom(this.companyTalentsInternal),
        companyTalents,
        x => x.talentId
      ),
    });
  }

  @Mutation
  SET_SHOW_REMOVED_USERS(v: boolean) {
    this.showRemovedUsers = v;
  }

  @Mutation
  SET_GRITIFY_NEWS(v: GritifyNewsItem[]) {
    this.gritifyNews = v;
  }

  @Mutation
  SET_LAST_VISITS(v: LastVisitedItem[]) {
    this.lastVisits = v;
  }

  @Mutation
  SET_NOTIFICATIONS(v: NotificationItem[]) {
    this.notifications = v;
  }

  @Mutation
  SET_LOAD_MORE_NOTIFICATIONS_TOKEN(v: string | null) {
    this.loadMoreNotificationsToken = v;
  }

  isConnectedToSignalR = false;

  @Mutation
  SET_IS_CONNECTED_TO_SIGNAL_R(v: boolean) {
    this.isConnectedToSignalR = v;
  }

  isDisconnected = false;

  @Mutation
  SET_IS_SIDCONNECTED(v: boolean) {
    this.isDisconnected = v;
  }

  @Action
  async connectSignalR(v: { lang: string }) {
    const token = await TokenHelpers.getToken();

    if (!this.isConnectedToSignalR) {
      const companyUrl =
        process.env.ENVIRONMENT === "Production"
          ? "https://realtime.gritify.io/api"
          : process.env.ENVIRONMENT === "Stage"
          ? "https://realtime-stage.gritify.io/api"
          : "https://gritify-signalr-api-test.azurewebsites.net/api";

      if (($msal.data?.account?.idTokenClaims as any)?.companyId) {
        companyBaseStore.SET_IS_CONNECTED_TO_SIGNAL_R(true);
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(`${companyUrl}`, {
            // .withUrl("https://gritify-signalr-api-test.azurewebsites.net/api", {
            accessTokenFactory: () => {
              return token;
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .withAutomaticReconnect()
          .build();

        connection.onreconnecting(_ => {
          companyBaseStore.SET_IS_SIDCONNECTED(true);
        });

        connection.onreconnected(_ => {
          // companyBaseStore.SET_IS_SIDCONNECTED(false);
        });

        const userId = ($msal.data?.account?.idTokenClaims as any).sub;

        connection.on(userId, (data: SingalRMessage) => {
          SignalRHelper.handleMessage({
            lang: v.lang,
            message: data,
            userId,
            companyId: authStore.companyId,
          });
        });

        connection.onclose(() => console.log("signal r company disconnected"));

        connection
          .start()
          .then(() => console.log("signal r company startad"))
          .catch(err => console.error("nått gick fel att starta", err));
      }
    }
  }

  @Action
  async addLastVisit(v: { visitItem: LastVisitedItemToAdd }) {
    const token = await TokenHelpers.getToken();

    await companyBaseStore.loadLastVisits();

    const latestVisitItem = companyBaseStore.lastVisits
      .slice()
      .sort((a, b) => <any>b.date - <any>a.date)[0];

    const isDifferentFromLatest =
      !latestVisitItem ||
      !SortHelper.getLastVisitIsEqual(latestVisitItem, v.visitItem);

    if (isDifferentFromLatest) {
      await companyUserService.postVisit({ item: v.visitItem, token });

      companyBaseStore.SET_LAST_VISITS([
        ...this.lastVisits,
        { ...v.visitItem, date: new Date() },
      ]);
    }
  }

  gritifyNewsAreLoaded = false;

  @Mutation
  SET_NEWS_LOADED(v: boolean) {
    this.gritifyNewsAreLoaded = v;
  }

  @Action
  async loadGritifyNews() {
    if (!this.gritifyNewsAreLoaded) {
      const token = await TokenHelpers.getToken();

      const news = await companyUserService.getGritifyNews({
        accessToken: token,
      });

      companyBaseStore.SET_GRITIFY_NEWS(news);

      companyBaseStore.SET_NEWS_LOADED(true);
    }
  }

  loadingNewsRemovals: string[] = [];

  @Mutation
  SET_LOADING_NEWS_REMOVALS(v: { newsId: string; loading: boolean }) {
    this.loadingNewsRemovals = v.loading
      ? [...this.loadingNewsRemovals, v.newsId]
      : this.loadingNewsRemovals.filter(x => x !== v.newsId);
  }

  @Action
  async removeNewsItem(v: { newsId: string }) {
    try {
      companyBaseStore.SET_LOADING_NEWS_REMOVALS({
        loading: true,
        newsId: v.newsId,
      });
      const token = await TokenHelpers.getToken();
      await companyUserService.removeNewsItem({
        newsId: v.newsId,
        token,
      });

      companyBaseStore.SET_GRITIFY_NEWS(
        this.gritifyNews.filter(x => x.id !== v.newsId)
      );
    } finally {
      companyBaseStore.SET_LOADING_NEWS_REMOVALS({
        loading: false,
        newsId: v.newsId,
      });
    }
  }

  lastVisitsAreLoaded = false;

  @Mutation
  SET_LAST_VISITS_LOADED(v: boolean) {
    this.lastVisitsAreLoaded = v;
  }

  @Action
  async loadLastVisits() {
    if (!this.lastVisitsAreLoaded) {
      const token = await TokenHelpers.getToken();

      const visits = await companyUserService.getLastVisits({
        accessToken: token,
      });

      companyBaseStore.SET_LAST_VISITS(visits);
      companyBaseStore.SET_LAST_VISITS_LOADED(true);
    }
  }

  numberOfUnseenNotifications: {
    numberOfNofitications: number;
    companyId: string;
  }[] = [];

  get numberOfUnseenNotificationsByCompanyId(): Record<string, number> {
    return this.numberOfUnseenNotifications.reduce(
      (acc: Record<string, number>, x) => {
        return {
          ...acc,
          [x.companyId]: x.numberOfNofitications,
        };
      },
      {}
    );
  }

  @Mutation
  SET_NUMBER_OF_UNSEEN_NOTIFICATIONS(
    v: { numberOfNofitications: number; companyId: string }[]
  ) {
    this.numberOfUnseenNotifications = v;
  }

  @Action
  async setNotificationRead(v: { notificationId: string; isRead: boolean }) {
    const token = await TokenHelpers.getToken();
    await companyUserService.setNotificationRead({
      isRead: v.isRead,
      notificationId: v.notificationId,
      token,
    });

    companyBaseStore.SET_NOTIFICATIONS(
      this.notifications.map(n => {
        if (n.id === v.notificationId) {
          return {
            ...n,
            isRead: v.isRead,
          };
        }

        return n;
      })
    );
  }

  unseenCountIsLoaded = false;

  @Mutation
  SET_UNSEEN_COUNT_LOADED(v: boolean) {
    this.unseenCountIsLoaded = v;
  }

  @Action
  async loadNumberOfUnseenNotifications(v: { force: boolean }) {
    if (v.force || !this.unseenCountIsLoaded) {
      const token = await TokenHelpers.getToken();

      const result = await companyUserService.getUnSeenNotificationsCount({
        accessToken: token,
      });

      companyBaseStore.SET_UNSEEN_COUNT_LOADED(true);

      companyBaseStore.SET_NUMBER_OF_UNSEEN_NOTIFICATIONS(result);
    }
  }

  @Action
  async setNotificationsSeen() {
    const token = await TokenHelpers.getToken();
    await companyUserService.setNotificationsSeen({
      token,
    });

    companyBaseStore.SET_NUMBER_OF_UNSEEN_NOTIFICATIONS(
      this.numberOfUnseenNotifications.map(x => {
        if (x.companyId === authStore.companyId) {
          return {
            ...x,
            numberOfNofitications: 0,
          };
        }

        return x;
      })
    );
  }

  loadingNotifications = false;

  @Mutation
  SET_LOADING_NOTIFICATIONS(v: boolean) {
    this.loadingNotifications = v;
  }

  @Action
  async loadMoreNotifications() {
    companyBaseStore.SET_LOADING_NOTIFICATIONS(true);
    const token = await TokenHelpers.getToken();

    const result = await companyUserService.getNotifications({
      accessToken: token,
      pageSize: this.notificationsPageSize,
      continuationToken: this.loadMoreNotificationsToken,
    });

    companyBaseStore.SET_LOAD_MORE_NOTIFICATIONS_TOKEN(result.token);

    await jobbOfferStore.loadCompanyTeamMembers();

    await jobbOfferStore.loadRecruitmentList();

    const users = jobbOfferStore.companyMembers;

    const moreNotifications = result.notifications
      .map(
        NotificationMapper.mapFromDbDto(users, jobbOfferStore.recruitmentList)
      )
      .filter(n => !!n);

    companyBaseStore.SET_NOTIFICATIONS([
      ...this.notifications,
      ...moreNotifications,
    ]);

    companyBaseStore.SET_LOADING_NOTIFICATIONS(false);
  }

  userDetails: UserDetails = {
    firstName: "",
    lastName: "",
    lang: "sv",
    companies: [],
    defaultCompanyId: null,
  };

  @Mutation
  SET_USER_DETAILS(v: UserDetails) {
    this.userDetails = v;
  }

  currentUserIsLoaded = false;
  @Mutation
  SET_CURRENT_USER_IS_LOADED(v: boolean) {
    this.currentUserIsLoaded = v;
  }

  @Action
  async loadUserDetails() {
    if (!this.currentUserIsLoaded) {
      const token = await TokenHelpers.getToken();

      const userDetails = await jobOfferService.getUserDetails({ token });

      companyBaseStore.SET_USER_DETAILS(userDetails);
      companyBaseStore.SET_CURRENT_USER_IS_LOADED(true);
    }
  }

  @Mutation
  SET_COMPANY_DETAILS_LOADED(v: boolean) {
    this.companyDetailsAreLoaded = v;
  }

  companyDetailsObject: CompanyObject | null = null;

  get accessFee(): number {
    const companyObject = this.companyDetailsObject;

    const defaultFee = defaultAccessFeeYearly;

    if (!companyObject) {
      return defaultFee;
    }

    return getMonthlyAccessFee(companyObject.pricing?.accessFee ?? defaultFee);
  }

  get isFixedRateCustomer() {
    const companyObject = this.companyDetailsObject;

    if (!companyObject?.pricingModel) {
      return true;
    }

    return [PricingModels.FixedPrice, PricingModels.NotSelected].includes(
      companyObject.pricingModel
    );
  }

  @Action
  toggleFreeAccount() {
    const companyObject = this.companyDetailsObject;

    if (this.isFreeAccount) {
      companyBaseStore.SET_COMPANY_DETAILS_OBJECT({
        ...companyObject!,
        pricingModel: PricingModels.Explore,
      });
    } else {
      companyBaseStore.SET_COMPANY_DETAILS_OBJECT({
        ...companyObject!,
        pricingModel: PricingModels.AccessSuccess,
      });
    }
  }

  get isFreeAccount() {
    const companyObject = this.companyDetailsObject;
    return companyObject?.pricingModel === PricingModels.Explore;
  }

  get isAccessSuccessAccount() {
    const companyObject = this.companyDetailsObject;
    return companyObject?.pricingModel === PricingModels.AccessSuccess;
  }

  @Action
  async upgradeCompanyToAccessSuccess(v: {
    invoiceEmail: string;
    contactName: string;
    orgNumber: string;
  }) {
    const token = await TokenHelpers.getToken();

    await upgradeCompanyToAccessSuccess({
      token,
      dto: v,
    });

    companyBaseStore.SET_COMPANY_DETAILS_OBJECT(
      this.companyDetailsObject
        ? {
            ...this.companyDetailsObject,
            pricingModel: PricingModels.AccessSuccess,
            accessSuccessInfo: {
              invoiceEmail: v.invoiceEmail,
              contactName: v.contactName,
              orgNumber: v.orgNumber,
            },
          }
        : null
    );
  }

  @Mutation
  SET_COMPANY_DETAILS_OBJECT(v: CompanyObject | null) {
    this.companyDetailsObject = v;
  }

  get companyName(): string | null {
    return this.companyDetailsObject?.companyName ?? null;
  }

  get companyBranchId(): string | null {
    return this.companyDetailsObject?.branchId ?? null;
  }

  @Action
  async setDoneWithCompanyStepper() {
    const token = await TokenHelpers.getToken();

    await companyUserService.setDoneWithCompanyStepper({
      token,
    });

    companyBaseStore.SET_COMPANY_DETAILS_OBJECT(
      this.companyDetailsObject
        ? {
            ...this.companyDetailsObject,
            showNewCompanyStepper: false,
          }
        : null
    );
  }

  @Action
  async loadCompanyDetails() {
    if (!this.companyDetailsAreLoaded) {
      const token = await TokenHelpers.getToken();

      const results = await companyUserService.getCompanyDetails({
        accessToken: token,
      });

      companyBaseStore.SET_COMPANY_DETAILS_OBJECT(results);

      companyBaseStore.SET_COMPANY_DETAILS_LOADED(true);
    }
  }

  @Action
  async setDontShowATSTips() {
    const token = await TokenHelpers.getToken();

    await setDontShowATSTips({
      accessToken: token,
    });

    if (this.companyDetailsObject) {
      companyBaseStore.SET_COMPANY_DETAILS_OBJECT({
        ...this.companyDetailsObject,
        dontShowATSTips: true,
      });
    }
  }

  @Action
  async setCompanyLanguage(v: { lang: string }) {
    const token = await TokenHelpers.getToken();

    await setCompanyLanguage({
      accessToken: token,
      lang: v.lang,
    });

    if (this.companyDetailsObject) {
      companyBaseStore.SET_COMPANY_DETAILS_OBJECT({
        ...this.companyDetailsObject,
        lang: v.lang,
      });
    }
  }

  titleRecommendationsInternal: ThingToLoad<
    { titleText: string; roleId: string }[]
  > = {
    type: "notFetched",
  };

  get titleRecommendationsForCompany(): {
    titleText: string;
    roleId: string;
  }[] {
    return TokenHelpers.GetArrayFrom(this.titleRecommendationsInternal);
  }

  get loadingTitleRecommendationsForCompany(): boolean {
    return this.titleRecommendationsInternal.type === "loading";
  }

  @Mutation
  SET_TITLE_RECOMMENDATIONS_FOR_COMPANY(
    v: ThingToLoad<{ titleText: string; roleId: string }[]>
  ) {
    this.titleRecommendationsInternal = v;
  }

  @Action
  async loadTitleRecommendationsForCompany() {
    const result = TokenHelpers.getThingToLoadOrExit(
      this.titleRecommendationsInternal,
      false
    );

    if (result.type === "exit") {
      return;
    }

    companyBaseStore.SET_TITLE_RECOMMENDATIONS_FOR_COMPANY(result);

    const token = await TokenHelpers.getToken();

    const titleRecommendations = await companyUserService.getTitleRecommendationsForCompany(
      {
        accessToken: token,
      }
    );

    companyBaseStore.SET_TITLE_RECOMMENDATIONS_FOR_COMPANY({
      type: "loaded",
      value: titleRecommendations,
    });
  }

  talentConversationsUnreadCountInternal = 0;

  @Mutation
  SET_TALENT_CONVERSATIONS_UNREAD_COUNT(v: number) {
    this.talentConversationsUnreadCountInternal = v;
  }

  talentConversationsUnreadCountIsLoaded = false;

  @Mutation SET_TALENT_CONVERSATIONS_UNREAD_COUNT_IS_LOADED(v: boolean) {
    this.talentConversationsUnreadCountIsLoaded = v;
  }

  @Action
  async loadTalentConversationsUnreadCount(v: { force: boolean }) {
    if (v.force || !this.talentConversationsUnreadCountIsLoaded) {
      const token = await TokenHelpers.getToken();

      const result = await companyUserService.getTalentConversationsUnreadCount(
        {
          accessToken: token,
        }
      );

      companyBaseStore.SET_TALENT_CONVERSATIONS_UNREAD_COUNT_IS_LOADED(true);
      companyBaseStore.SET_TALENT_CONVERSATIONS_UNREAD_COUNT(result);
    }
  }

  talentConversations: TalentConversations = { type: "NotLoaded" };

  @Mutation
  SET_TALENT_CONVERSATIONS(v: TalentConversations) {
    this.talentConversations = v;
  }

  @Action
  async loadTalentConversations() {
    if (this.talentConversations.type === "NotLoaded") {
      companyBaseStore.SET_TALENT_CONVERSATIONS({
        type: "Loading",
      });
      const token = await TokenHelpers.getToken();
      const result = await companyUserService.getTalentConversations({
        accessToken: token,
      });

      companyBaseStore.SET_TALENT_CONVERSATIONS({
        type: "Loaded",
        conversations: result,
      });
    }
  }

  get talentConversationsUnreadCount(): number {
    if (this.talentConversations.type === "Loaded") {
      return this.talentConversations.conversations.reduce((acc: number, c) => {
        return acc + (c.isRead ? 0 : 1);
      }, 0);
    }

    return this.talentConversationsUnreadCountInternal;
  }

  @Action
  async setTalentConversationRead(v: {
    talentId: string;
    recruitmentId: string | null;
    conversationId: string;
    isRead: boolean;
  }) {
    const token = await TokenHelpers.getToken();
    await companyUserService.setTalentConversationRead({
      isRead: v.isRead,
      recruitmentId: v.recruitmentId,
      talentId: v.talentId,
      token,
    });

    if (this.talentConversations.type === "Loaded") {
      companyBaseStore.SET_TALENT_CONVERSATIONS({
        type: "Loaded",
        conversations: this.talentConversations.conversations.map(x => {
          if (x.id === v.conversationId) {
            return {
              ...x,
              isRead: v.isRead,
            };
          }
          return x;
        }),
      });
    }
  }

  @Action
  async upsertTeamTailorApiKey(v: { apiKey: string | null }) {
    const token = await TokenHelpers.getToken();

    const apiKeyOrNull = v.apiKey === "" ? null : v.apiKey;

    await companyUserService.upsertTeamTailorApiKey({
      token,
      apiKey: apiKeyOrNull,
    });

    jobbOfferStore.SET_TEAM_TAILOR_JOBS_LOADED(false);

    const loadedCompanyObject = this.companyDetailsObject;

    if (loadedCompanyObject != null) {
      companyBaseStore.SET_COMPANY_DETAILS_OBJECT({
        ...loadedCompanyObject,
        teamTailorApiKey: apiKeyOrNull,
      });
    }
  }

  @Action
  async addJobylonIntegrationRequest() {
    const token = await TokenHelpers.getToken();

    const result = await companyUserService.addJobylonIntegrationRequest({
      token,
    });

    const loadedCompanyObject = this.companyDetailsObject;

    if (loadedCompanyObject != null) {
      companyBaseStore.SET_COMPANY_DETAILS_OBJECT({
        ...loadedCompanyObject,
        jobylonIntegrationRequestObj: result,
      });
    }
  }

  @Action
  async updateUsersDetails(v: {
    firstName: string;
    lastName: string;
    lang: string;
    defaultCompanyId: string | null;
  }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.updateUsersDetails({
      accessToken: token,
      firstName: v.firstName,
      lastName: v.lastName,
      defaultCompanyId: v.defaultCompanyId,
      lang: v.lang,
    });

    companyBaseStore.SET_USER_DETAILS({
      ...this.userDetails,
      ...v,
    });

    companyBaseStore.SET_NEWS_LOADED(false);

    if (authStore.userId) {
      jobbOfferStore.SET_COMPANY_TEAM_MEMBERS(
        jobbOfferStore.companyMembers.map(m => {
          if (m.userId === authStore.userId) {
            return {
              ...m,
              firstName: v.firstName,
              lastName: v.lastName,
              lang: v.lang,
            };
          }
          return m;
        })
      );
    }
  }

  notificationsAreLoaded = false;

  @Mutation
  SET_NOTIFICATIONS_LOADED(v: boolean) {
    this.notificationsAreLoaded = v;
  }

  teamTailorTalentIdsInternal: GetTeamTailorTalentIdsDto[] = [];
  teamTailorTalentIdsAreLoaded = false;

  @Mutation
  SET_TEAM_TAILOR_TALENT_IDS(v: GetTeamTailorTalentIdsDto[]) {
    this.teamTailorTalentIdsInternal = v;
  }

  get teamTailorTalentIds(): GetTeamTailorTalentIdsDto[] {
    return this.teamTailorTalentIdsAreLoaded
      ? this.teamTailorTalentIdsInternal
      : (this.companyTalentsInternal.type === "loaded"
          ? this.companyTalentsInternal.value
          : []
        ).flatMap(x => {
          if (!x.teamTailorObject) {
            return [];
          }

          return {
            talentId: x.talentId,
            recruitmentIds: x.teamTailorObject.addedFromRecruitmentIds,
            teamTailorId: x.teamTailorObject.teamTailorId,
          };
        });
  }

  @Mutation
  SET_TEAM_TAILOR_TALENT_IDS_LOADED(v: boolean) {
    this.teamTailorTalentIdsAreLoaded = v;
  }

  @Action
  async loadTeamTailorTalentIds() {
    if (!this.teamTailorTalentIdsAreLoaded) {
      const token = await TokenHelpers.getToken();

      const ids = await CompanySettingsService.getTeamTailorTalentIds({
        accessToken: token,
      });

      companyBaseStore.SET_TEAM_TAILOR_TALENT_IDS(ids);
      companyBaseStore.SET_TEAM_TAILOR_TALENT_IDS_LOADED(true);
    }
  }

  @Action
  async loadNotifications() {
    if (!this.notificationsAreLoaded) {
      companyBaseStore.SET_LOADING_NOTIFICATIONS(true);
      const token = await TokenHelpers.getToken();
      const result = await companyUserService.getNotifications({
        accessToken: token,
        pageSize: this.notificationsPageSize,
      });

      companyBaseStore.SET_LOAD_MORE_NOTIFICATIONS_TOKEN(result.token);

      await jobbOfferStore.loadCompanyTeamMembers();

      await jobbOfferStore.loadRecruitmentList();

      const users = jobbOfferStore.companyMembers;

      companyBaseStore.SET_NOTIFICATIONS(
        result.notifications
          .map(
            NotificationMapper.mapFromDbDto(
              users,
              jobbOfferStore.recruitmentList
            )
          )
          .filter(n => !!n)
      );

      companyBaseStore.SET_NOTIFICATIONS_LOADED(true);
      companyBaseStore.SET_LOADING_NOTIFICATIONS(false);
    }
  }

  @Action
  async updateBlockedCompaniesForSourcing(v: {
    companies: ListObjectNullable[];
  }) {
    const token = await TokenHelpers.getToken();

    await CompanySettingsService.updateBlockedCompaniesForSourcing({
      accessToken: token,
      companies: v.companies,
    });

    companyBaseStore.SET_COMPANY_DETAILS_OBJECT(
      this.companyDetailsObject
        ? {
            ...this.companyDetailsObject,
            blockedCompaniesForSourcing: v.companies,
          }
        : null
    );
  }

  @Action
  async updateCompanyEmail(v: { email: string | null }) {
    const token = await TokenHelpers.getToken();

    await CompanySettingsService.updateCompanyEmail({
      accessToken: token,
      email: v.email,
    });

    companyBaseStore.SET_COMPANY_DETAILS_OBJECT(
      this.companyDetailsObject
        ? {
            ...this.companyDetailsObject,
            companyEmail: v.email,
          }
        : null
    );
  }

  @Action
  async addTalentIdToBlockedTalentIds(v: { talentId: string }) {
    const token = await TokenHelpers.getToken();

    await CompanySettingsService.addTalentIdToBlockedTalentIds({
      accessToken: token,
      talentId: v.talentId,
    });

    companyBaseStore.SET_COMPANY_DETAILS_OBJECT(
      this.companyDetailsObject
        ? {
            ...this.companyDetailsObject,
            blockedTalentIds: [
              ...this.companyDetailsObject.blockedTalentIds,
              v.talentId,
            ],
          }
        : null
    );
  }
}
