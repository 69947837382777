// import uuidv1 from "uuid/v1";
import axios from "axios";
import {
  ExitedSourcingRobot,
  GetMatchingProfilesUnfilteredDto,
  GetSortedTalentsDto,
  getTalentsFromRobotRequirementsTestDto,
  SourcingRobotDefinitionDto,
  SourcingRobotRequirementsObject,
} from "~/models/types";

interface SourcingRobotDefinitionDtoInBackEnd {
  skillIds: string[];
  sortedTaskIds: string[];
  bonusPercentage: number;
  languageIds: string[];
  maxSalaryMinYear: number;
  maxSalary: number;
  minYear: number;
  maxYear: number | null;
  minYearsOfEducation: number;
  isStopped: boolean;
  skillPrioGroups: { prio: number; skillIds: string[] }[];
}

function mapToBackEndDto(
  v: SourcingRobotDefinitionDto
): SourcingRobotDefinitionDtoInBackEnd {
  return { ...v, skillIds: [] };
}

function mapToFrontEndDto(
  v: SourcingRobotDefinitionDtoInBackEnd
): SourcingRobotDefinitionDto {
  return v;
}

const runFromTestServer = true;

const sourcingRobotApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://sourcingrobot.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runFromTestServer
    ? "https://gritify-sourcingrobot-test.azurewebsites.net/api"
    : "http://localhost:7073/api";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default class SourcingRobotService {
  public static getRecommendedTalentsForRecruitment(v: {
    accessToken: string;
    recruitmentId: string;
    sourcingRobotDefinitionDto: SourcingRobotDefinitionDto;
  }): Promise<GetSortedTalentsDto[]> {
    return axios
      .post<GetSortedTalentsDto[]>(
        `${sourcingRobotApiUrl}/GetRecommendedTalentsForRecruitment`,
        {
          recruitmentId: v.recruitmentId,
          definitionDto: mapToBackEndDto(v.sourcingRobotDefinitionDto),
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getTalentsFromRobotRequirementsTest(v: {
    accessToken: string;
    sourcingRobotRequirementsObject: SourcingRobotRequirementsObject;
  }): Promise<getTalentsFromRobotRequirementsTestDto> {
    return axios
      .post<getTalentsFromRobotRequirementsTestDto>(
        `${sourcingRobotApiUrl}/GetTalentsFromRobotRequirementsTest`,
        v.sourcingRobotRequirementsObject,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getTalentsStatisticsFromDefinition(v: {
    accessToken: string;
    recruitmentId: string;
    talentId: string;
    sourcingRobotDefinitionDto: SourcingRobotDefinitionDto;
  }): Promise<GetSortedTalentsDto> {
    return axios
      .post<GetSortedTalentsDto>(
        `${sourcingRobotApiUrl}/GetTalentsStatisticsFromDefinition`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
          definitionDto: mapToBackEndDto(v.sourcingRobotDefinitionDto),
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static getSourcingRobotRequirements(v: {
    accessToken: string;
    recruitmentId: string;
  }): Promise<SourcingRobotRequirementsObject | null> {
    return axios
      .get<SourcingRobotRequirementsObject | null | "">(
        `${sourcingRobotApiUrl}/GetSourcingRobotRequirements?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => {
        return x.data || null;
      });
  }

  public static getMatchingProfilesUnfiltered(v: {
    accessToken: string;
    recruitmentId: string;
  }): Promise<GetMatchingProfilesUnfilteredDto[]> {
    return axios
      .get<GetMatchingProfilesUnfilteredDto[]>(
        `${sourcingRobotApiUrl}/GetMatchingProfilesUnfiltered?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static upsertSourcingRobotRequirements(v: {
    accessToken: string;
    sourcingRobotRequirementsObject: SourcingRobotRequirementsObject;
  }): Promise<SourcingRobotRequirementsObject | null> {
    return axios
      .post<SourcingRobotRequirementsObject | null>(
        `${gritifyApiUrl}/UpsertSourcingRobotRequirements`,
        v.sourcingRobotRequirementsObject,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static upsertExitedSourcingRobot(v: {
    accessToken: string;
    exitedSourcingRobot: ExitedSourcingRobot;
  }): Promise<void> {
    return axios
      .post<void>(
        `${sourcingRobotApiUrl}/UpsertExitedSourcingRobot`,
        v.exitedSourcingRobot,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static GetSourcingRobotDefinition(v: {
    accessToken: string;
    recruitmentId: string;
  }): Promise<SourcingRobotDefinitionDto | null> {
    return axios
      .get<SourcingRobotDefinitionDtoInBackEnd | null>(
        `${sourcingRobotApiUrl}/GetSourcingRobotDefinition?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => (x.data ? mapToFrontEndDto(x.data) : null));
  }

  public static getYearsOfExperienceForRole(v: {
    accessToken: string;
    roleId: string;
    talentId: string;
  }): Promise<number> {
    return axios
      .get<number>(
        `${sourcingRobotApiUrl}/GetYearsOfExperienceForRole?roleId=${v.roleId}&talentId=${v.talentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static createSourcingRobotDefinition(v: {
    accessToken: string;
    recruitmentId: string;
    sourcingRobotDefinitionDto: SourcingRobotDefinitionDto;
  }): Promise<void> {
    return axios
      .post<void>(
        `${sourcingRobotApiUrl}/CreateSourcingRobotDefinition`,
        {
          recruitmentId: v.recruitmentId,
          definitionDto: mapToBackEndDto(v.sourcingRobotDefinitionDto),
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static setPausedOnRobotDefinition(v: {
    accessToken: string;
    recruitmentId: string;
    paused: boolean;
  }): Promise<void> {
    return axios
      .post<void>(
        `${sourcingRobotApiUrl}/SetPausedOnRobotDefinition`,
        {
          recruitmentId: v.recruitmentId,
          paused: v.paused,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }
}
