// import uuidv1 from "uuid/v1";
import axios from "axios";
// import moment from "moment";

import { JobbOfferBaseInfo } from "../models/JobbOfferBaseInfo";
import {
  GetTalentsForSourcingDto,
  TalentForFiltering,
  RecruitmentTeamMember,
  RecruitmentListItem,
  TrelloBoardTalentResponse,
  UpdateTalentCardsDto,
  TalentMessage,
  JobOfferVisaulToUpdate,
  GetTeamCommentDto,
  OpportunitySourcing,
  UserDetails,
  GetSharedTalentDto,
  GetRecruitmentTeamMemberDto,
  GetTalentMessageDto,
  GetRecruitmentListItemDto,
  RecruitmentCompanyAction,
  TrelloBoardTalent,
  LibraryResourceType,
  ExternalTalentProfileSaved,
  LangObject,
  GetNumberOfTalentsTestSearchDto,
  InviteUserRequestDto,
  RecruitmentTalentWithDaysLeftDto,
  RecruitmentLogObject,
  TalentProfileForCompanyDto,
  GetRoleWithSkillsAndTasksDto,
} from "~/models/types";
import VisualJobOfferHelper from "~/helpers/visualJobOfferHelper";
import TalentHelpers from "~/helpers/talentHelpers";
import { ListObject } from "~/models/ListObject";
import { mapRecruitmentListItem } from "~/helpers/RecruitmentHelperFunctions";

const runPublicOnTest = true;

const publicUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://public.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://public-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runPublicOnTest
    ? "https://gritify-publicapi-test.azurewebsites.net/api"
    : "http://localhost:7072/api";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default {
  createRecruitment(
    createDto: {
      baseInfo: JobbOfferBaseInfo;
    },
    accessToken: string
  ): Promise<GetRecruitmentListItemDto> {
    return axios
      .post<GetRecruitmentListItemDto>(
        `${gritifyApiUrl}/CreateRecruitment`,
        createDto,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  endJobOffer(v: {
    recruitmentId: string;
    message: string | null;
    token: string;
  }) {
    return axios
      .post<void>(
        `${gritifyApiUrl}/CloseRecruitment`,
        {
          recruitmentId: v.recruitmentId,
          message: v.message,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  async updateTalentCards(
    obj: UpdateTalentCardsDto,
    accessToken: string
  ): Promise<number> {
    const response = await axios.put<number>(
      `${gritifyApiUrl}/MoveTalentOnBoard`,
      obj,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  },
  getLogs(
    recruitmentId: string,
    accessToken: string
    // talentId?: string
  ): Promise<RecruitmentLogObject[]> {
    return axios
      .get<RecruitmentLogObject[]>(
        `${gritifyApiUrl}/GetRecruitmentLogs?recruitmentId=${recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r =>
        r.data.map(x => {
          const resultLog: RecruitmentLogObject = {
            ...x,
            date: new Date(x.date),
          };

          return resultLog;
        })
      );
  },
  getRecruitmentMatchesTalentLocations(v: {
    recruitmentId: string;
    accessToken: string;
    talentId: string;
  }): Promise<boolean> {
    return axios
      .get<boolean>(
        `${gritifyApiUrl}/GetRecruitmentMatchesTalentLocations?recruitmentId=${v.recruitmentId}&talentId=${v.talentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  getLogsForTalent(
    recruitmentId: string,
    accessToken: string,
    talentId?: string
  ): Promise<RecruitmentLogObject[]> {
    return axios
      .get<RecruitmentLogObject[]>(
        `${gritifyApiUrl}/GetRecruitmentLogs?recruitmentId=${recruitmentId}&talentId=${talentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r =>
        r.data.map(x => {
          const resultLog: RecruitmentLogObject = {
            ...x,
            date: new Date(x.date),
          };

          return resultLog;
        })
      );
  },
  getTeamComments(
    recruitmentId: string,
    accessToken: string
  ): Promise<GetTeamCommentDto[]> {
    return axios
      .get<GetTeamCommentDto[]>(
        `${gritifyApiUrl}/GetComments?recruitmentId=${recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data.map(c => ({
          ...c,
          senderId: c.senderId || null,
          date: new Date(c.date),
        }));
      });
  },
  getTeamCommentsOnTalent(
    recruitmentId: string,
    talentId: string,
    accessToken: string
  ): Promise<GetTeamCommentDto[]> {
    return axios
      .get<GetTeamCommentDto[]>(
        `${gritifyApiUrl}/GetComments?recruitmentId=${recruitmentId}&talentId=${talentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data.map(c => ({
          ...c,
          senderId: c.senderId || null,
          date: new Date(c.date),
        }));
      });
  },
  addTeamComment(
    recruitmentId: string,
    comment: string,
    mentionIds: string[],
    recruitmentTitle: string,
    accessToken: string
  ): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/CreateComment`,
        {
          recruitmentId,
          recruitmentTitle,
          comment,
          mentionIds,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  getExternalTalentProfiles(v: {
    recruitmentId: string;
    token: string;
  }): Promise<ExternalTalentProfileSaved[]> {
    return axios
      .get<ExternalTalentProfileSaved[]>(
        `${gritifyApiUrl}/GetExternalTalentProfiles?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  getPublicTalentDocumentUrls(v: {
    recruitmentId: string;
    email: string;
    token: string;
  }): Promise<string[]> {
    return axios
      .get<string[]>(
        `${gritifyApiUrl}/GetPublicTalentDocuments?recruitmentId=${v.recruitmentId}&email=${v.email}`,
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  getExternalFormDatasByCompanyId(v: {
    token: string;
  }): Promise<{ recruitmentId: string; formId: string }[]> {
    return axios
      .get<{ recruitmentId: string; formId: string }[]>(
        `${gritifyApiUrl}/GetExternalFormDatasByCompanyId`,
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  addTeamCommentOnTalent(
    recruitmentId: string,
    comment: string,
    talentId: string,
    mentionIds: string[],
    recruitmentTitle: string,
    talentName: string,
    accessToken: string
  ): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/CreateComment`,
        {
          recruitmentId,
          recruitmentTitle,
          comment,
          talentId,
          talentName,
          mentionIds,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  postCommentOnSharedTalent(v: {
    token: string;
    comment: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicUrl}/Recruitment/Comment/Public`,
        {
          comment: v.comment,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  addMessageToTalent(
    message: string,
    talentId: string,
    recruitmentId: string,
    accessToken: string
  ): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/CreateMessage`,
        {
          recruitmentId,
          comment: message,
          talentId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getTalentMessages(
    talentId: string,
    recruitmentId: string,
    accessToken: string
  ): Promise<TalentMessage[]> {
    return axios
      .get<GetTalentMessageDto[]>(
        `${gritifyApiUrl}/GetMessages?recruitmentId=${recruitmentId}&talentId=${talentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data.map(c => ({ ...c, date: new Date(c.date) }));
      });
  },

  getSharedTalent(v: {
    token: string;
    lang: string;
  }): Promise<GetSharedTalentDto> {
    return axios
      .get<GetSharedTalentDto>(`${publicUrl}/GetTalentProfilePublic?lang=sv`, {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(r => {
        const newOrOldType = r.data;
        return {
          ...newOrOldType,
          senderName: newOrOldType.senderName || "",
        };
      });
  },

  shareTalent(v: {
    talentId: string;
    recruitmentId: string;
    emails: string[];
    message: string;
    talentName: string;
    titleName: string;
    token: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/ShareTalent`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
          message: v.message,
          email: v.emails,
          talentName: v.talentName,
          titleName: v.titleName,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getTeamTailorJobs(v: { token: string }): Promise<ListObject[]> {
    return axios
      .get<ListObject[]>(`${gritifyApiUrl}/GetTeamTailorJobs`, {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },

  addTalentToTeamTailor(v: {
    token: string;
    talentId: string;
    recruitmentId: string;
    teamTailorJobId: string | null;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/AddTalentToTeamTailor`,
        {
          talentId: v.talentId,
          recruitmentId: v.recruitmentId,
          teamTailorJobId: v.teamTailorJobId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  addTalentToJobylon(v: {
    token: string;
    talentId: string;
    recruitmentId: string;
    jobylonJobId: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/AddTalentToJobylon`,
        {
          talentId: v.talentId,
          recruitmentId: v.recruitmentId,
          jobylonJobId: v.jobylonJobId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getJobylonJobs(v: { token: string }): Promise<ListObject[]> {
    return axios
      .get<ListObject[]>(`${gritifyApiUrl}/GetJobylonJobs`, {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },

  updateTeamTailorJobId(v: {
    token: string;
    recruitmentId: string;
    teamTailorJobId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/UpdateTeamTailorJobId`,
        {
          recruitmentId: v.recruitmentId,
          teamTailorJobId: v.teamTailorJobId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getRecruitmentsForList(accessToken: string): Promise<RecruitmentListItem[]> {
    return axios
      .get<GetRecruitmentListItemDto[]>(`${gritifyApiUrl}/GetRecruitmentList`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data.map(mapRecruitmentListItem);
      });
  },

  getCanEditOfficeAddress(v: {
    accessToken: string;
    officeId: string;
  }): Promise<boolean> {
    return axios
      .get<boolean>(
        `${gritifyApiUrl}/GetCanEditOfficeAddress?officeId=${v.officeId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  updateJobOfferBaseInfo(
    baseInfo: JobbOfferBaseInfo,
    recruitmentId: string,
    accessToken: string
  ): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateBaseInfo?recruitmentId=${recruitmentId}`,
        baseInfo,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  setRecruitmentStarted(v: {
    recruitmentId: string;
    activateRobot: { type: "Yes"; manual: boolean } | { type: "No" };
    accessToken: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/SetRecruitmentStarted`,
        {
          recruitmentId: v.recruitmentId,
          realRobotIsActive:
            v.activateRobot.type === "Yes" && !v.activateRobot.manual,
          manualRobotIsActive:
            v.activateRobot.type === "Yes" && v.activateRobot.manual,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  updateJobbOfferVisual(
    jobbOfferVisual: JobOfferVisaulToUpdate,
    recruitmentId: string,
    accessToken: string
  ): Promise<string> {
    return axios
      .put<string>(
        `${gritifyApiUrl}/SaveJobOfferVisual?recruitmentId=${recruitmentId}`,
        jobbOfferVisual,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getTrelloBoardLanes(
    recruitmentId: string,
    accessToken: string,
    lang: string
  ): Promise<string[]> {
    return axios
      .get<string[]>(
        `${gritifyApiUrl}/GetRecruitmentLanes?recruitmentId=${recruitmentId}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },

  getIsUsedInActiveRecruitments(v: {
    resourceId: string;
    accessToken: string;
    type: LibraryResourceType;
  }): Promise<boolean> {
    return axios
      .post<boolean>(
        `${gritifyApiUrl}/GetIsUsedInActiveRecruitments`,
        {
          resourceId: v.resourceId,
          type: v.type,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },

  removeTalentFromRecruitment(v: {
    talentId: string;
    recruitmentId: string;
    message: string | null;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RecruitmentCompanyAction?action=${RecruitmentCompanyAction.Remove}`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
          message: v.message,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },
  declineTalentFromRecruitment(v: {
    talentId: string;
    recruitmentId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RecruitmentCompanyAction?action=${RecruitmentCompanyAction.Decline}`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },
  saveTalentToRecruitment(
    talentId: string,
    recruitmentId: string,
    accessToken: string
  ): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RecruitmentCompanyAction?action=${RecruitmentCompanyAction.Save}`,
        {
          recruitmentId,
          talentId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },
  inviteTalentToRecruitment(
    talentId: string,
    recruitmentId: string,
    accessToken: string
  ): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RecruitmentCompanyAction?action=${RecruitmentCompanyAction.Invite}`,
        {
          recruitmentId,
          talentId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },
  inviteTalentAsSourcingRobot(v: {
    talentId: string;
    recruitmentId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/InviteTalentAsSourcingRobot`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },
  updateRatingOnTalent(v: {
    talentId: string;
    recruitmentId: string;
    accessToken: string;
    rating: number | null;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/RateTalent`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
          rating: v.rating,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data || "ny kandidat");
  },

  getTrelloboardTalents(
    recruitmentId: string,
    accessToken: string
  ): Promise<TrelloBoardTalentResponse> {
    return axios
      .get<TrelloBoardTalentResponse>(
        `${gritifyApiUrl}/GetRecruitmentTalents?recruitmentId=${recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(x => x.data);
  },

  getRecruitmentTalentsByRecruitmentIds(v: {
    recruitmentIds: string[];
    talentId: string;
    accessToken: string;
    locations: ListObject[];
  }): Promise<TrelloBoardTalent[]> {
    return axios
      .post<RecruitmentTalentWithDaysLeftDto[]>(
        `${gritifyApiUrl}/GetRecruitmentTalentsByRecruitmentIds`,
        {
          recruitmentIds: v.recruitmentIds,
          talentId: v.talentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x =>
        x.data.map(rt => TalentHelpers.mapFromServerDto(rt, v.locations))
      );
  },

  anonymizeTalent(v: {
    recruitmentId: string;
    talentId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/AnonymizeTalent`,
        {
          recruitmentId: v.recruitmentId,
          talentId: v.talentId,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  },

  getJobOfferBaseInfo(
    recruitmentId: string,
    accessToken: string
  ): Promise<JobbOfferBaseInfo> {
    return axios
      .get<JobbOfferBaseInfo>(
        `${gritifyApiUrl}/GetBaseInfo?recruitmentId=${recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getSkillsByIds(v: {
    skillIds: string[];
    token: string;
  }): Promise<LangObject[]> {
    return axios
      .post<LangObject[]>(
        `${gritifyApiUrl}/GetSkillsByIds`,
        { skillIds: [...new Set(v.skillIds)] },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getSchoolsByIds(v: {
    schoolIds: string[];
    token: string;
  }): Promise<ListObject[]> {
    return axios
      .post<ListObject[]>(
        `${gritifyApiUrl}/GetSchoolsByIds`,
        { schoolIds: v.schoolIds },
        {
          headers: {
            Authorization: `Bearer ${v.token}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  getJobbOfferVisual(
    recruitmentId: string,
    accessToken: string
  ): Promise<JobOfferVisaulToUpdate | null> {
    return axios
      .get<JobOfferVisaulToUpdate | null>(
        `${gritifyApiUrl}/GetVisualOffer?recruitmentId=${recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        if (!r.data) {
          return null;
        }

        return VisualJobOfferHelper.getMappedVisual(r.data);
      });
  },

  updateOpportunitySourcing(v: {
    opportunitySourcing: OpportunitySourcing;
    recruitmentId: string;
    accessToken: string;
  }): Promise<string> {
    return axios
      .put<string>(
        `${gritifyApiUrl}/UpdateOpportunitySourcing?recruitmentId=${v.recruitmentId}`,
        v.opportunitySourcing,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  getOpportunitySourcing(v: {
    recruitmentId: string;
    accessToken: string;
  }): Promise<OpportunitySourcing | null> {
    return axios
      .get<OpportunitySourcing | null>(
        `${gritifyApiUrl}/GetOpportunitySourcing?recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        const result: OpportunitySourcing | null = r.data
          ? {
              ...r.data,
              sourcing: {
                ...r.data.sourcing,
                requirementGroups: r.data.sourcing.requirementGroups || [],
              },
            }
          : null;
        return result;
      });

    // return axios
    // .get<void>(
    //   `${companyUrl}/OpportunitySourcing?recruitmentId=${v.recruitmentId}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${v.accessToken}`,
    //     },
    //   }
    // )
    // .then(r => {
    //   return r.data;
    // });
  },

  GetTalentPdfForCompany(v: {
    talentId: string;
    recruitmentId: string;
    accessToken: string;
    lang: string;
  }): Promise<string | null> {
    return axios
      .get<string | null>(
        `${gritifyApiUrl}/GetTalentPdfForCompany?lang=${v.lang}&talentId=${v.talentId}&recruitmentId=${v.recruitmentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },

  getTalentProfileById(v: {
    talentId: string;
    accessToken: string;
    lang: string;
  }): Promise<TalentProfileForCompanyDto> {
    return axios
      .get<TalentProfileForCompanyDto>(
        `${gritifyApiUrl}/GetTalentProfileCompany?lang=${v.lang}&talentId=${v.talentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return TalentHelpers.mapTalentProfileForCompany(r.data);
      });
  },
  addCompanyTeamMember(v: {
    requestDto: InviteUserRequestDto;
    accessToken: string;
  }): Promise<string> {
    return axios.post(`${gritifyApiUrl}/InviteUser`, v.requestDto, {
      headers: {
        Authorization: `Bearer ${v.accessToken}`,
      },
    });
  },
  resendUserInvite(v: { email: string; accessToken: string }): Promise<string> {
    return axios.post(
      `${gritifyApiUrl}/ResendUserInvite`,
      {
        email: v.email,
      },
      {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      }
    );
  },
  changeEmailOnCompanyUser(v: {
    oldEmail: string;
    newEmail: string;
    accessToken: string;
  }): Promise<string> {
    return axios.post(
      `${gritifyApiUrl}/ChangeEmailOnCompanyUser`,
      {
        oldEmail: v.oldEmail,
        newEmail: v.newEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      }
    );
  },
  getCompanyTeamMembers(accessToken: string): Promise<RecruitmentTeamMember[]> {
    return axios
      .get<GetRecruitmentTeamMemberDto[]>(`${gritifyApiUrl}/GetUsers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data.map(m => ({
          ...m,
          recruitmentIds: m.recruitmentIds || [],
          isRemoved: m.isRemoved || false,
        }));
      });
  },
  updateCompanyTeamMember(v: {
    accessToken: string;
    user: RecruitmentTeamMember;
  }): Promise<void> {
    return axios
      .put<void>(`${gritifyApiUrl}/UpdateUser`, v.user, {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  },
  getUserDetails(v: { token: string }): Promise<UserDetails> {
    return axios
      .get<UserDetails>(`${gritifyApiUrl}/GetCurrentUser`, {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      })
      .then(r => {
        return (
          r.data || {
            firstName: "",
            lastName: "",
          }
        );
      });
  },
  updateUsersDetails(v: {
    accessToken: string;
    firstName: string;
    lastName: string;
    defaultCompanyId: string | null;
    lang: string;
  }): Promise<void> {
    return axios
      .put<void>(
        `${gritifyApiUrl}/UpdateCurrentUser`,
        {
          firstName: v.firstName,
          lastName: v.lastName,
          defaultCompanyId: v.defaultCompanyId,
          lang: v.lang,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  addRecruitmentTeamMember(
    jobOfferId: string,
    memberId: string,
    accessToken: string
  ): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/AddTeamMember`,
        { recruitmentId: jobOfferId, userId: memberId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  updateRecruitmentRecTalentIsOpened(v: {
    recruitmentId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/UpdateRecruitmentRecTalentIsOpened`,
        { recruitmentId: v.recruitmentId },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },

  deleteRecruitmentTeamMember(
    jobOfferId: string,
    memberId: string,
    accessToken: string
  ): Promise<void> {
    return axios
      .delete<void>(
        `${gritifyApiUrl}/DeleteTeamMember?recruitmentId=${jobOfferId}&userId=${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data;
      });
  },
  async getTalentsForSourcing(
    searchParams: GetTalentsForSourcingDto,
    accessToken: string
  ): Promise<{ items: TalentForFiltering[]; count: number }> {
    const talentsFromResponse = await axios.post<TalentForFiltering[] | number>(
      `${gritifyApiUrl}/TalentFilter`,
      searchParams,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return typeof talentsFromResponse.data === "number"
      ? {
          items: [],
          count: talentsFromResponse.data,
        }
      : {
          items: talentsFromResponse.data.map(x => ({
            ...x,
            branchExperience: x.branchExperience || {},
            languageIds: x.languageIds || [],
            responsibilities: x.responsibilities || [],
            schoolDomains: x.schoolDomains || [],
            schools: x.schools || [],
            skills: x.skills || [],
            tasks: x.tasks || [],
            workRoles: x.workRoles || [],
          })),
          count: talentsFromResponse.data.length,
        };
  },
  async getNumberOfTalentsTestSearch(v: {
    searchParams: GetNumberOfTalentsTestSearchDto;
    accessToken: string;
  }): Promise<number> {
    const result = await axios.post<number>(
      `${gritifyApiUrl}/GetNumberOfTalentsTestSearch`,
      { ...v.searchParams, isRemote: v.searchParams.type === "Remote" },
      {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      }
    );

    return result.data;
  },
  getRoleWithSkillsAndTasks(v: {
    accessToken: string;
    roleId: string;
  }): Promise<GetRoleWithSkillsAndTasksDto> {
    return axios
      .get<GetRoleWithSkillsAndTasksDto>(
        `${gritifyApiUrl}/GetRoleWithSkillsAndTasks`,
        {
          params: {
            roleId: v.roleId,
          },
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  },
  async getTalentsForSourcingNew(
    searchParams: GetTalentsForSourcingDto,
    accessToken: string
  ): Promise<{ items: TalentForFiltering[]; count: number }> {
    const talentsFromResponse = await axios.post<TalentForFiltering[] | number>(
      `${gritifyApiUrl}/TalentFilter`,
      searchParams,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return typeof talentsFromResponse.data === "number"
      ? {
          items: [],
          count: talentsFromResponse.data,
        }
      : {
          items: talentsFromResponse.data.map(x => ({
            ...x,
            branchExperience: x.branchExperience || {},
            languageIds: x.languageIds || [],
            responsibilities: x.responsibilities || [],
            schoolDomains: x.schoolDomains || [],
            schools: x.schools || [],
            skills: x.skills || [],
            tasks: x.tasks || [],
            workRoles: x.workRoles || [],
            numberOfRolePreferences: x.numberOfRolePreferences || 0,
            latestEmploymentRoleIds: x.latestEmploymentRoleIds || [],
          })),
          count: talentsFromResponse.data.length,
        };

    // const talentsFromResponse = await axios.post<TalentForFiltering[] | number>(
    //   "https://gritify-talent-searchprofiles-test-dev.azurewebsites.net/api/TalentFilter",
    //   searchParams,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   }
    // );

    // return typeof talentsFromResponse.data === "number"
    //   ? {
    //       items: [],
    //       count: talentsFromResponse.data,
    //     }
    //   : {
    //       items: talentsFromResponse.data,
    //       count: talentsFromResponse.data.length,
    //     };
  },
};

export interface GetFeaturedJobsDto {
  title: ListObject;
  areaName: string;
  newPrice: number;
  oldPrice: number;
}

export const getFeaturedJobs = (v: { token: string }) => {
  return axios
    .get<GetFeaturedJobsDto[]>(`${gritifyApiUrl}/GetFeaturedJobs`, {
      headers: {
        Authorization: `Bearer ${v.token}`,
      },
    })
    .then(x => x.data);
};
export const setHasReachedRecapDate = (v: {
  token: string;
  recruitmentId: string;
}) => {
  return axios
    .post<void>(
      `${gritifyApiUrl}/SetHasReachedRecapDate`,
      {
        recruitmentId: v.recruitmentId,
      },
      {
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      }
    )
    .then(x => x.data);
};
