import axios from "axios";
import {
  UpsertDeclineWantMoreTipDto,
  WantMoreCandidatesTip,
} from "~/models/types";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export const setDontShowATSTips = (v: {
  accessToken: string;
}): Promise<void> => {
  return axios
    .post<void>(
      `${gritifyApiUrl}/SetDontShowATSTips`,
      {},
      {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      }
    )
    .then(r => r.data);
};

export const setCompanyLanguage = (v: {
  accessToken: string;
  lang: string;
}): Promise<void> => {
  return axios
    .post<void>(
      `${gritifyApiUrl}/SetCompanyLanguage`,
      {
        lang: v.lang,
      },
      {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      }
    )
    .then(r => r.data);
};

export const updatePerksByLang = (v: {
  accessToken: string;
  lang: string;
}): Promise<string[]> => {
  return axios
    .post<string[]>(
      `${gritifyApiUrl}/UpdatePerksByLang`,
      {
        lang: v.lang,
      },
      {
        headers: {
          Authorization: `Bearer ${v.accessToken}`,
        },
      }
    )
    .then(r => r.data);
};

export const getWantMoreCandidatesTips = (v: {
  token: string;
  recruitmentId: string;
}): Promise<WantMoreCandidatesTip[]> =>
  axios
    .get<WantMoreCandidatesTip[]>(
      `${gritifyApiUrl}/GetWantMoreCandidatesTips`,
      {
        params: {
          recruitmentId: v.recruitmentId,
        },
        headers: {
          Authorization: `Bearer ${v.token}`,
        },
      }
    )
    .then(r => r.data);

export const upsertDeclineWantMoreTip = (v: {
  token: string;
  dto: UpsertDeclineWantMoreTipDto;
}): Promise<string> =>
  axios
    .post<string>(`${gritifyApiUrl}/UpsertDeclineWantMoreTip`, v.dto, {
      headers: {
        Authorization: `Bearer ${v.token}`,
      },
    })
    .then(r => r.data);

export type UpgradeCompanyToAccessSuccessDto = {
  invoiceEmail: string;
  contactName: string;
  orgNumber: string;
};

export const upgradeCompanyToAccessSuccess = (v: {
  token: string;
  dto: UpgradeCompanyToAccessSuccessDto;
}): Promise<string> =>
  axios
    .post<string>(`${gritifyApiUrl}/UpgradeCompanyToAccessSuccess`, v.dto, {
      headers: {
        Authorization: `Bearer ${v.token}`,
      },
    })
    .then(r => r.data);
