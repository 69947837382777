// import uuidv1 from "uuid/v1";
import axios from "axios";
import TalentHelpers from "~/helpers/talentHelpers";
import { WorkFromHome } from "~/models/WorkFromHome";
import {
  GetPoolMessagesDto,
  GetPoolMessagesDtoWithStringDates,
  GetPoolTalentsForCompanyDto,
  GetPublicTalentPoolDataDto,
  GetTalentPoolsForTalentDto,
  GetTalentPoolsForTalentDtoWithDates,
  PoolTalentLog,
  PublishedCompoanyProfileDto,
  TalentPoolTalent,
  TalentProfileForCompanyDto,
  TalentPoolTeamComment,
  TalentPoolTeamCommentFromBackend,
} from "~/models/types";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

const runPublicOnTest = true;

const publicApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://public.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://public-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runPublicOnTest
    ? "https://gritify-publicapi-test.azurewebsites.net/api"
    : "http://localhost:7072/api";

export default class TalentPoolService {
  public static addTalentToPoolFromCompany(v: {
    talentId: string;
    message: string | null;
    removeFromRecruitmentId: string | null;
    accessToken: string;
  }): Promise<TalentPoolTalent> {
    return axios
      .post<GetPoolTalentsForCompanyDto>(
        `${gritifyApiUrl}/AddTalentToPoolFromCompany`,
        {
          talentId: v.talentId,
          message: v.message,
          removeFromRecruitmentId: v.removeFromRecruitmentId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => this.MapToTalentPoolTalent(x.data));
  }

  public static removeTalentFromPool(v: {
    talentId: string;
    message: string | null;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/RemoveTalentFromPool`,
        {
          talentId: v.talentId,
          message: v.message,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static setIsInformedOnTalentPool(v: {
    companyId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/SetIsInformedOnTalentPool`,
        {
          companyId: v.companyId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static acceptTalentPoolFromTalent(v: {
    companyId: string;
    accessToken: string;
    recruitmentId: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/AcceptTalentPoolFromTalent`,
        {
          companyId: v.companyId,
          recruitmentId: v.recruitmentId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static declineTalentPoolFromTalent(v: {
    companyId: string;
    accessToken: string;
  }): Promise<void> {
    return axios
      .post<void>(
        `${gritifyApiUrl}/DeclineTalentPoolFromTalent`,
        {
          companyId: v.companyId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static addPoolMessageFromCompany(v: {
    messageText: string;
    talentId: string;
    accessToken: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/AddPoolMessageFromCompany`,
        {
          messageText: v.messageText,
          talentId: v.talentId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static updateRatingOnPoolTalent(v: {
    rating: number | null;
    talentId: string;
    accessToken: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/UpdateRatingOnPoolTalent`,
        {
          rating: v.rating,
          talentId: v.talentId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static addPoolMessageFromTalent(v: {
    messageText: string;
    companyId: string;
    accessToken: string;
  }): Promise<string> {
    return axios
      .post<string>(
        `${gritifyApiUrl}/AddPoolMessageFromTalent`,
        {
          messageText: v.messageText,
          companyId: v.companyId,
        },
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data);
  }

  public static mapToGetPoolMessagesDto(
    source: GetPoolMessagesDtoWithStringDates
  ): GetPoolMessagesDto {
    const result: GetPoolMessagesDto = !source.companySender
      ? {
          type: "FromTalent",
          ...source,
          date: new Date(source.date),
        }
      : {
          type: "FromCompany",
          ...source,
          companySender: source.companySender,
          date: new Date(source.date),
        };
    return result;
  }

  public static getPoolMessagesForCompany(v: {
    accessToken: string;
    talentId: string;
  }): Promise<GetPoolMessagesDto[]> {
    return axios
      .get<GetPoolMessagesDtoWithStringDates[]>(
        `${gritifyApiUrl}/GetPoolMessagesForCompany?talentId=${v.talentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data.map(x => this.mapToGetPoolMessagesDto(x)));
  }

  public static GetPoolTalentLogs(v: {
    accessToken: string;
    talentId: string;
  }): Promise<PoolTalentLog[]> {
    return axios
      .get<PoolTalentLog[]>(
        `${gritifyApiUrl}/GetPoolTalentLogs?talentId=${v.talentId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x =>
        x.data.map(l => {
          return {
            ...l,
            date: new Date(l.date),
          };
        })
      );
  }

  public static getPoolMessagesForTalent(v: {
    accessToken: string;
    companyId: string;
  }): Promise<GetPoolMessagesDto[]> {
    return axios
      .get<GetPoolMessagesDtoWithStringDates[]>(
        `${gritifyApiUrl}/GetPoolMessagesForTalent?companyId=${v.companyId}`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data.map(x => this.mapToGetPoolMessagesDto(x)));
  }

  public static getTalentPoolsForTalent(v: {
    accessToken: string;
  }): Promise<GetTalentPoolsForTalentDtoWithDates[]> {
    return axios
      .get<GetTalentPoolsForTalentDto[]>(
        `${gritifyApiUrl}/GetTalentPoolsForTalent`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x =>
        x.data.map(x => {
          const result: GetTalentPoolsForTalentDtoWithDates = {
            ...x,
            accepted: x.accepted !== null ? new Date(x.accepted) : null,
            declined: x.declined !== null ? new Date(x.declined) : null,
            removed: x.removed !== null ? new Date(x.removed) : null,
            invited: x.invited !== null ? new Date(x.invited) : null,
          };

          return result;
        })
      );
  }

  public static getPublicTalentPoolData(v: {
    accessToken: string;
  }): Promise<GetPublicTalentPoolDataDto> {
    return axios
      .get<{
        talentPool: GetTalentPoolsForTalentDto;
        companyProfile: PublishedCompoanyProfileDto;
        email: string | null;
        phoneNumber: string | null;
      }>(`${publicApiUrl}/GetPublicTalentPoolData`, {
        headers: {
          authorization: `Bearer ${v.accessToken}`,
        },
      })
      .then(r => {
        const x = r.data.talentPool;
        const result: GetTalentPoolsForTalentDtoWithDates = {
          ...x,
          accepted: x.accepted !== null ? new Date(x.accepted) : null,
          declined: x.declined !== null ? new Date(x.declined) : null,
          removed: x.removed !== null ? new Date(x.removed) : null,
          invited: x.invited !== null ? new Date(x.invited) : null,
        };

        return {
          ...r.data,
          talentPool: result,
        };
      });
  }

  public static getPoolTalentsForCompany(v: {
    accessToken: string;
  }): Promise<TalentPoolTalent[]> {
    return axios
      .get<GetPoolTalentsForCompanyDto[]>(
        `${gritifyApiUrl}/GetPoolTalentsForCompany`,
        {
          headers: {
            authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(x => x.data.map(this.MapToTalentPoolTalent));
  }

  static getTalentProfileById(v: {
    talentId: string;
    accessToken: string;
    lang: string;
  }): Promise<TalentProfileForCompanyDto> {
    return axios
      .get<TalentProfileForCompanyDto>(
        `${gritifyApiUrl}/GetTalentProfileForCompanyPool?lang=${v.lang}&talentId=${v.talentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => TalentHelpers.mapTalentProfileForCompany(r.data));
  }

  static getPoolTeamCommentsForTalentId(v: {
    talentId: string;
    accessToken: string;
  }): Promise<TalentPoolTeamComment[]> {
    return axios
      .get<TalentPoolTeamCommentFromBackend[]>(
        `${gritifyApiUrl}/GetPoolTeamCommentsForTalentId?talentId=${v.talentId}`,
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data.map(x => ({
          ...x,
          date: new Date(x.date),
        }));
      });
  }

  static addPoolTeamComment(v: {
    accessToken: string;
    talentId: string;
    commentText: string;
    mentionIds: string[];
  }): Promise<{
    commentId: string;
  }> {
    return axios
      .post<{
        commentId: string;
      }>(
        `${gritifyApiUrl}/AddPoolTeamComment`,
        {
          talentId: v.talentId,
          commentText: v.commentText,
          mentionIds: v.mentionIds,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  }

  static acceptPublicTalentPool(v: { accessToken: string }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/AcceptPublicTalentPool`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  }

  static declinePublicTalentPool(v: { accessToken: string }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/DeclinePublicTalentPool`,
        {},
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  }

  static updateTalentContactInfoFromPublicPool(v: {
    accessToken: string;
    phoneNumber: string | null;
    contactEmail: string | null;
  }): Promise<void> {
    return axios
      .post<void>(
        `${publicApiUrl}/UpdateTalentContactInfoFromPublicPool`,
        {
          phoneNumber: v.phoneNumber,
          contactEmail: v.contactEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${v.accessToken}`,
          },
        }
      )
      .then(r => r.data);
  }

  private static MapToTalentPoolTalent(
    x: GetPoolTalentsForCompanyDto
  ): TalentPoolTalent {
    const result: TalentPoolTalent = {
      firstName: x.firstName,
      lastName: x.lastName,
      highestEducationDegree: null,
      id: x.talentId,
      languageIds: [],
      latestRoleId: null,
      dateCreated: new Date(x.dateCreated),
      accepted: x.accepted ? new Date(x.accepted) : null,
      declined: x.declined ? new Date(x.declined) : null,
      invited: x.invited ? new Date(x.invited) : null,
      removed: x.removed ? new Date(x.removed) : null,
      locationIds: x.requirements?.locationIds ?? [],
      preferenceRoleIds: x.requirements?.roleIds ?? [],
      requireNoTrips: x.requirements?.requireNoTrips ?? false,
      requireNoConsultants: x.requirements?.requireNoConsultants ?? false,
      requireNoStartups: x.requirements?.requireNoStartups ?? false,
      requireNoPublicSectors: x.requirements?.requireNoPublicSectors ?? false,
      requireStableSalary: x.requirements?.requireStableSalary ?? false,
      requireWorkFromHome:
        x.requirements?.requireWorkFromHome ?? WorkFromHome.No,
      responsibilityExperience: null,
      responsibilityMusts: x.requirements?.responsibilityMustIds ?? [],
      salary: x.requirements?.salary ?? 0,
      latestEmployments: x.latestEmployments,
      skillIds: x.skillIds,
      talentId: x.talentId,
      taskIds: x.taskIds,
      yearsOfExperience: x.yearsOfExperience,
      number: x.number,
      rejectedCompanyIds: x.requirements?.rejectedCompanyIds ?? [],
      rating: x.rating,
      declinedInRecruitmentId: x.declinedInRecruitmentId,
    };

    return result;
  }
}
