







































































































import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import GrButtonNew from "./GrButtonNew.vue";
import CrispHelper from "~/helpers/CrispHelper";

@Component({
  components: {
    GrButtonNew,
  },
})
export default class TalentModalWrapper extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly value!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly loadingSave!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly loadingCancel!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly loadingDelete!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabledSave!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly closeButtonOnly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly includeDelete!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly persistent!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly canGoBack!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly canGoNext!: boolean;

  @Prop({ type: String, default: null })
  readonly headerText!: string | null;

  @Prop({ type: String, default: null })
  readonly saveText!: string | null;

  @Prop({ type: Boolean, default: false })
  readonly isStepper!: boolean;

  @Prop({ type: Number, default: null })
  readonly maxWidth!: number | null;

  @Emit("input")
  inputEmit() {}

  get dialog() {
    return this.value;
  }

  get maxWidthOrDefault() {
    return this.maxWidth ?? 1300;
  }

  set dialog(value: boolean) {
    this.inputEmit();
  }

  get nextOrSaveTextComputed() {
    if (this.isStepper) {
      return this.canGoNext
        ? this.$t("Nästa").toString()
        : this.$t("Slutför").toString();
    }
    return this.saveText ?? this.$t("Spara & stäng").toString();
  }

  @Emit("save")
  saveEmit() {}

  @Emit("cancel")
  cancelEmit() {}

  @Emit("delete")
  deleteEmit() {}

  @Emit("back")
  backEmit() {}

  @Watch("dialog")
  modalOpenChanged(v: boolean) {
    if (v) {
      if (this.$vuetify.breakpoint.smAndDown) {
        CrispHelper.hideChat();
      }
    } else {
      CrispHelper.showChat();
    }
  }
}
