import axios from "axios";
import {
  GetWorkingProfileDto,
  PublishedCompoanyProfileDto,
  PublishProfileResponse,
} from "~/models/types";
import CompanyProfileHelper from "~/helpers/CompanyProfileHelper";

const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test"
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default class ProfileService {
  static updateProfile(
    profile: GetWorkingProfileDto,
    accessToken: string
  ): Promise<void> {
    return axios
      .put<void>(`${gritifyApiUrl}/UpdateWorkingProfile`, profile, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  static publishProfile(accessToken: string): Promise<PublishProfileResponse> {
    return axios
      .post<PublishProfileResponse>(`${gritifyApiUrl}/PublishProfile`, null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return r.data;
      });
  }

  static getPublishedProfile(
    accessToken: string
  ): Promise<PublishedCompoanyProfileDto | null> {
    return axios
      .get<PublishedCompoanyProfileDto | null>(
        `${gritifyApiUrl}/GetPublishedProfileCompany`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(r => {
        return r.data
          ? {
              ...r.data,
              teamVisible: r.data.teamVisible || false,
              logoIncludesName: r.data.logoIncludesName,
            }
          : null;
      });
  }

  static getProfile(accessToken: string): Promise<GetWorkingProfileDto> {
    return axios
      .get<GetWorkingProfileDto>(`${gritifyApiUrl}/GetWorkingProfile`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(r => {
        return CompanyProfileHelper.getMappedProfile(r.data);
      });
  }
}
